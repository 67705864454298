import React from 'react';

const NoMatch = () => (
  <div id="page-404">
    <h1>404</h1>
    <h4>Nothing exists here!</h4>
  </div>
);

export default NoMatch;
