import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Loading from './Loading';

const LoadingModal = ({ onClick }) => (
  <Modal className="input-item-modal keypad-modal" onClick={onClick} isOpen>
    <ModalHeader>One Moment...</ModalHeader>
    <ModalBody><Loading /></ModalBody>
  </Modal>
);

LoadingModal.propTypes = {
  onClick: PropTypes.func,
};

LoadingModal.defaultProps = {
  onClick: null,
};

export default LoadingModal;
