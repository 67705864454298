import React from 'react';
import { ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

const ModalContent = ({ header, children }) => (
  <>
    <ModalHeader>{header}</ModalHeader>
    <ModalBody>{children}</ModalBody>
  </>
);

ModalContent.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ModalContent.defaultProps = {
  header: '',
};

export default ModalContent;
