import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// normally place initial state outside component, but this would fire immediately.
const ConfirmTimer = ({ limit, timerEnds, parentheses }) => {
  const [{ startTime, time }, setState] = useState({
    startTime: new Date(),
    time: 0,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const elapsedTime = Math.floor((new Date() - startTime) / 1000);
      if (elapsedTime > limit) {
        clearInterval(timer);
        timerEnds();
      } else {
        setState({ startTime, time: elapsedTime });
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const timeLeft = Math.max(limit - time, 0);
  const timeLeftFormatted = parentheses ? `(${timeLeft}s)` : `${timeLeft}s`;

  return <div className="confirm-timer-cmp">{timeLeftFormatted}</div>;
};

ConfirmTimer.propTypes = {
  parentheses: PropTypes.bool,
  limit: PropTypes.number.isRequired,
  timerEnds: PropTypes.func.isRequired,
};

ConfirmTimer.defaultProps = {
  parentheses: false,
};

export default ConfirmTimer;
