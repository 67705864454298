import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { BusinessContext, RunnerContext } from '../contexts';
import LockerLegend from './LockerLegend';
import LockersView from './LockersView';
import LockerModals from './LockerModals';
import { CUSTOMER_NEEDS_ASSISTANCE_SUB } from '../../apollo';

const initialState = {
  chosenLocker: null,
  isGridView: true,
  showThisModal: null,
  isOverflowItem: false,
};

const Lockers = ({ location: { state: { runner = null } = {} }, businessId }) => {
  const [state, setState] = useState(initialState);
  const [idleState, setIdleState] = useState({
    lastIdleTime: new Date().getTime(),
    isIdle: false,
  });
  let idleTimer;

  // placed because of the parameter names conflict/eslint.
  const selectModal = (showThisModal, chosenLocker, newState) => (
    setState({
      ...state,
      showThisModal,
      chosenLocker,
      ...newState,
    })
  );
  const handleLockerClick = (e, locker) => selectModal('InputItemModal', locker);
  const handleLongLockerClick = (e, locker) => selectModal('DisableModal', locker);
  const moveItem = (e, locker) => selectModal('MoveItemModal', locker);
  const editItem = (e, locker) => selectModal('EditItemModal', locker);
  const removeItem = (e, locker, newState = {}) => selectModal('RemoveAndConfirmModal', locker, newState);
  const getLockerPin = (e, locker) => selectModal('GetLockerPinModal', locker);
  const toggleModal = () => (
    setState({
      ...state,
      showThisModal: null,
      chosenLocker: null,
      isOverflowItem: false,
    })
  );

  const {
    showThisModal,
    chosenLocker,
    isGridView,
    isOverflowItem,
  } = state;

  const { isIdle, lastIdleTime } = idleState;

  const handleAnyClick = () => setIdleState({ isIdle: false, lastIdleTime: new Date().getTime() });
  const toggleView = () => setState({ isGridView: !isGridView });

  const {
    data: { customerNeedsAssistance } = {},
    loading,
  } = useSubscription(CUSTOMER_NEEDS_ASSISTANCE_SUB, { variables: { businessId } });

  useEffect(() => {
    if (process.env.REACT_APP_APP_ENV !== 'local') {
      idleTimer = setInterval(() => {
        if (new Date().getTime() - lastIdleTime >= 1800000) setIdleState({ isIdle: true, lastIdleTime });
      }, 4000);
    }
    if (!loading && !showThisModal && customerNeedsAssistance) selectModal('AssistanceModal');

    return () => clearTimeout(idleTimer);
  }, [loading, showThisModal, customerNeedsAssistance]);

  const itemMethods = {
    move: moveItem,
    edit: editItem,
    remove: removeItem,
    pin: getLockerPin,
  };

  if (!runner || isIdle) return <Redirect to="/home" />;

  return (
    <RunnerContext.Provider value={runner}>
      <div className="App" onClick={handleAnyClick}>
        <div className="container locker-screen">
          <h2 className="status-screen-header">Order Status</h2>
          <LockersView
            isGridView={isGridView}
            toggleView={toggleView}
            handleLockerClick={handleLockerClick}
            handleLongLockerClick={handleLongLockerClick}
            itemMethods={itemMethods}
          />
        </div>
        <LockerLegend />
        <LockerModals
          showThisModal={showThisModal}
          handleAnyClick={handleAnyClick}
          toggleModal={toggleModal}
          chosenLocker={chosenLocker}
          isOverflowItem={isOverflowItem}
        />
      </div>
    </RunnerContext.Provider>
  );
};

const LockersWrapped = props => (
  <BusinessContext.Consumer>
    {({ id }) => <Lockers {...props} businessId={id} />}
  </BusinessContext.Consumer>
);

Lockers.propTypes = {
  location: PropTypes.object.isRequired,
  businessId: PropTypes.string.isRequired,
};

export default LockersWrapped;
