import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const KeypadKey = ({ onClick, value }) => {
  let buttonClass = value === 'Back' ? ' btn-danger' : '';
  buttonClass = buttonClass || (value === 'Submit' ? ' btn-success' : '');
  return (
    <div className="col-4">
      <Button onClick={() => onClick(value)} className={buttonClass}>{value}</Button>
    </div>
  );
};

KeypadKey.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.any,
};

KeypadKey.defaultProps = {
  value: null,
};

export default KeypadKey;
