import React from 'react';
import PropTypes from 'prop-types';
import OrderThumbIcon from '../../images/icons/order_thumb_icon.svg';

const OrderThumb = ({ orderTotal, isPaid, lockerPin }) => (
  <div className="customer-thumb">
    <div className="label-wrapper">
      <img src={OrderThumbIcon} alt="Order" />
      <span>Order</span>
    </div>
    <div className="details-list-wrapper">
      <ul className="details-list">
        <li className="major">
          <span>Order Total</span>
          <span className="value">{`$${parseFloat(orderTotal).toFixed(2)}`}</span>
        </li>
        <li>
          <span>Paid?</span>
          <span className="value">{isPaid ? 'YES' : 'NO'}</span>
        </li>
        {lockerPin && (
          <li>
            <span>Pin</span>
            <span className="value">{lockerPin}</span>
          </li>
        )}
      </ul>
    </div>
  </div>
);

OrderThumb.propTypes = {
  lockerPin: PropTypes.any.isRequired,
  orderTotal: PropTypes.number.isRequired,
  isPaid: PropTypes.bool.isRequired,
};

export default OrderThumb;
