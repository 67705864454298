import React from 'react';
import PropTypes from 'prop-types';
import ImageButton from './Buttons/ImageButton';
import CustomerPickupIcon from '../../images/icons/customerpickupicon.png';
import PickUpServiceIcon from '../../images/icons/deliveryserviceicon.png';

const SelectPickupType = ({ reactiveVar }) => {
  const handlePickupTypeButton = value => reactiveVar({ input: { formStep: value } });

  return (
    <div className="container-fluid">
      <div className="row justify-content-center pickup-type-buttons">
        <ImageButton
          img={CustomerPickupIcon}
          text="Customer"
          onClick={() => handlePickupTypeButton('CUSTOMERPICKUP')}
        />
        <ImageButton img={PickUpServiceIcon} onClick={() => handlePickupTypeButton('SERVICEPICKUP')}>
          Food Delivery
          <br />
          Service
        </ImageButton>
      </div>
    </div>
  );
};

SelectPickupType.propTypes = {
  reactiveVar: PropTypes.func.isRequired,
};

export default SelectPickupType;
