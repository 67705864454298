import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useQuery } from '@apollo/client';
import { GET_CUSTOMER_BY_PHONE, updateOrderDropOffForm } from '../../../../apollo';
import Keypad from '../../../miscellaneous/Keypad';
import { formatPhone } from '../../../../methods';
import { Loading } from '../../../miscellaneous/Loading';

const handleKeyboard = ({ target: { name, value } }) => (
  updateOrderDropOffForm({ fieldInput: { name, value: formatPhone(value) } })
);

const PhoneInputStep = ({ phoneNumberIsValid, customerPhone, searchCustomer }) => {
  const { data: { getCustomerByPhone } = {}, loading, error } = useQuery(GET_CUSTOMER_BY_PHONE, {
    variables: { phoneNumber: customerPhone, upsert: true },
    skip: !searchCustomer,
    fetchPolicy: 'network-only',
  });

  const handleSubmission = () => (
    customerPhone.length === 14
      ? updateOrderDropOffForm({ input: { searchCustomer: true, phoneNumberIsValid: true } })
      : updateOrderDropOffForm({ input: { phoneNumberIsValid: false } })
  );
  const handleKeypad = value => (
    customerPhone.length !== 14 && (
      updateOrderDropOffForm({
        fieldInput: { name: 'customerPhone', value: formatPhone(`${customerPhone}${value}`) },
      })
    )
  );
  const handleKeypadBack = () => (
    updateOrderDropOffForm({
      input: { phoneNumberIsValid: null, searchCustomer: false },
      fieldInput: { name: 'customerPhone', value: customerPhone.slice(0, -1) },
    })
  );

  if (error) return `Error: ${error}`;
  if (loading) return <Loading />;
  if (getCustomerByPhone) {
    const { id: customerId, name: value } = getCustomerByPhone;
    updateOrderDropOffForm({
      input: { customerId, formStep: 'CUSTOMERPICKUP|1' },
      fieldInput: { name: 'customerName', value },
    });
    return <div />;
  }

  return (
    <div className="order-modal-phone-input">
      <div className="container-fluid">
        <div className="row justify-content-center" />
        <Form onSubmit={handleSubmission}>
          <FormGroup>
            <Label for="phoneNumField" className="large-label faint">Enter Phone Number</Label>
            <Input
              id="phoneNumField"
              type="text"
              name="customerPhone"
              className="large-input pin-input"
              value={customerPhone}
              onChange={handleKeyboard}
              placeholder="(123) 456-7890"
              invalid={phoneNumberIsValid || phoneNumberIsValid === false}
              readOnly
            />
            {getCustomerByPhone === null && <span className="error-msg">That number does not belong to an active customer.</span>}
            {phoneNumberIsValid === false && <span className="error-msg">Phone number is invalid.</span>}
          </FormGroup>
        </Form>
      </div>
      <div className="row justify-content-center">
        <Keypad onClick={handleKeypad} lastRowFunc={[handleKeypadBack, handleKeypad, handleSubmission]} />
      </div>
    </div>
  );
};

PhoneInputStep.propTypes = {
  phoneNumberIsValid: PropTypes.bool,
  customerPhone: PropTypes.string,
  searchCustomer: PropTypes.bool.isRequired,
};

PhoneInputStep.defaultProps = {
  phoneNumberIsValid: false,
  customerPhone: '',
};

export default PhoneInputStep;

// Query w/ phone number
// if no person, then switch modal to this.
// enter in name, upon successful name entering, next
