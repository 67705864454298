import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Route, Redirect } from 'react-router-dom';
import { CURRENT_BUSINESS } from '../../apollo';
import { Loading } from '../miscellaneous/Loading';
import { BusinessContext } from '../contexts';

const AuthRouteComponent = (props) => {
  const { loading, error, data: { currentBusiness } = {} } = useQuery(CURRENT_BUSINESS);
  if (loading) return 'Loading';
  if (error) return `Error! ${error.message}`;
  if (!currentBusiness.stripeEnabled && props.path !== '/') return <Redirect to="/" />;

  return (
    <BusinessContext.Provider value={currentBusiness}>
      <Route {...props} />
    </BusinessContext.Provider>
  );
};

AuthRouteComponent.propTypes = {
  path: PropTypes.string.isRequired,
};

export default withAuthenticationRequired(AuthRouteComponent, {
  onRedirecting: Loading,
});
