import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { SubmitButton } from './Buttons';

const DoorDashPickupFields = ({
  loading,
  handleInput,
  fieldValues,
  handleSubmit,
  pickupService,
  error,
}) => (
  <>
    <FormGroup>
      <Label for="customerName" className="large-label faint">
        Customer&#39;s
        {' '}
        <b>First Name (Primer Nombre)</b>
      </Label>
      <Input
        type="text"
        name="customerName"
        className="large-input pin-input"
        value={fieldValues.customerName || ''}
        onChange={handleInput}
        autoComplete="off"
        autoFocus
      />
    </FormGroup>
    <FormGroup>
      <Label for="numOfItems" className="large-label faint"># of items (as shown on app)</Label>
      <Input
        type="number"
        name="numOfItems"
        className="large-input pin-input"
        value={fieldValues.numOfItems || ''}
        onChange={handleInput}
        autoComplete="off"
      />
    </FormGroup>
    {error && <span style={{ color: 'red' }}>Sorry! No orders found!</span>}
    <SubmitButton onClick={() => handleSubmit(pickupService)} loading={loading} />
  </>
);

DoorDashPickupFields.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  fieldValues: PropTypes.object.isRequired,
  pickupService: PropTypes.object.isRequired,
};

DoorDashPickupFields.defaultProps = {
  loading: false,
  error: null,
};

export default DoorDashPickupFields;
