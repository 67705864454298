import 'babel-polyfill';
import 'whatwg-fetch';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import history from './history';
import Routes from './components/Routes';
import ApolloProvider from './apollo';
import { unregister } from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/App.css';
import './styles/index.css';
import './styles/keypad.css';
import './styles/forms.css';
import './styles/customer.css';
import {
  SENTRY_DSN as dsn,
  AUTH0_DOMAIN,
  AUTH0_CLIENTID,
  AUTH0_CALLBACKURL,
  AUTH0_AUDIENCE,
} from './config';

// Error logging. Should be used in production. Can be use in staging.
if (dsn) Sentry.init({ dsn });

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace((appState && appState.returnTo) || window.location.pathname);
};

const App = () => (
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENTID}
    redirectUri={AUTH0_CALLBACKURL}
    audience={AUTH0_AUDIENCE}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens
  >
    <ApolloProvider>
      <Routes />
    </ApolloProvider>
  </Auth0Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
