import { InMemoryCache } from '@apollo/client';
import { orderDropOffFormVar, orderPickupFormVar } from './reactives';

export default new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        orderDropOffForm: {
          read() {
            return orderDropOffFormVar();
          },
        },
        orderPickupForm: {
          read() {
            return orderPickupFormVar();
          },
        },
      },
    },
  },
});
