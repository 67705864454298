import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Select from 'react-select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { DISABLE_LOCKER, GET_LOCKERS } from '../../../apollo';
import { LoadingModal } from '../../miscellaneous/Loading';

const options = [
  { value: 'Lock Issue', label: 'Lock Issue' },
  { value: 'Door Issue', label: 'Door Issue' },
  { value: 'Spill', label: 'Spill' },
  { value: 'Insulation Issue', label: 'Insulation Issue' },
];

const findOption = value => options.find(option => option.value === value);

const DisableModal = ({
  lockerData: { lockerNumber, isDisabled, disabledReason = '' },
  handleAnyClick,
  toggle,
  showModal,
  handleSubmit,
  error,
  data,
}) => {
  const [chosenOption, choseOption] = useState({});
  const option = findOption(chosenOption.value) || options[0];

  if (!lockerNumber) return false;
  if (data) {
    toggle();
    return null;
  }
  const title = `${isDisabled ? 'Enable' : 'Disable'} Box ${lockerNumber}?`;
  const submit = () => (
    handleSubmit({
      variables: {
        input: { lockerNumber, isDisabled: !isDisabled, disabledReason: option.value },
      },
    })
  );

  return (
    <Modal
      onClick={handleAnyClick}
      className="input-item-modal"
      isOpen={showModal}
      style={{ bottomBorderColor: 'black' }}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        {isDisabled ? (
          <div>{`Disabled Reason: ${disabledReason || 'N/A'}`}</div>
        ) : (
          <div className="container-fluid">
            <h5 className="modal-title">Reason: </h5>
            <Select
              name="disableReason"
              options={options}
              onChange={choseOption}
              value={option}
              isSearchable={false}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className="buttons-footer">
        <button
          className="btn btn-default"
          type="button"
          value={false}
          onClick={toggle}
        >
          Nope!
        </button>
        <button
          className="btn btn-danger"
          type="button"
          value
          onClick={submit}
        >
          Yes
        </button>
        {error && <span>{error}</span>}
      </ModalFooter>
    </Modal>
  );
};

const DisableModalMutation = (props) => {
  const [submit, { loading, error, data }] = useMutation(DISABLE_LOCKER, { refetchQueries: [{ query: GET_LOCKERS }] });
  if (loading) return <LoadingModal />;
  return (
    <DisableModal
      handleSubmit={submit}
      error={error}
      data={data}
      {...props}
    />
  );
};

DisableModal.propTypes = {
  data: PropTypes.object,
  lockerData: PropTypes.object.isRequired,
  handleAnyClick: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  error: PropTypes.any,
};

DisableModal.defaultProps = {
  data: null,
  error: null,
};

export default DisableModalMutation;
