import React from 'react';
import PropTypes from 'prop-types';

const InstructionContainer = ({
  step,
  text,
  information,
  children,
}) => (
  <div className="pickup-instruction-container">
    <div className="pickup-instruction">
      <span className="pickup-instruction-number">{step}</span>
      <span className="pickup-instruction-text">{text}</span>
    </div>
    {information && <p className="pickup-instruction-information">{information}</p>}
    {children}
  </div>
);

InstructionContainer.propTypes = {
  step: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  information: PropTypes.string,
  children: PropTypes.node,
};

InstructionContainer.defaultProps = {
  information: null,
  children: null,
};

export default InstructionContainer;
