import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';
import { SubmitButton } from './Buttons';

const UberEatsPickupField = ({
  loading,
  handleInput,
  fieldValues,
  handleSubmit,
  error,
}) => (
  <>
    <Label for="accessCodeField" className="large-label faint">Enter Order Code<br/>(Imprima codigo de orden)</Label>
    <Input
      id="accessCodeField"
      type="text"
      name="orderCode"
      className="large-input pin-input"
      value={fieldValues.orderCode || ''}
      onChange={handleInput}
      placeholder="-----"
      autoComplete="off"
      autoFocus
    />
    {error && <span style={{ color: 'red' }}>Sorry! No orders found!</span>}
    <SubmitButton onClick={() => handleSubmit('UBEREATS')} loading={loading} />
  </>
);

UberEatsPickupField.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  handleInput: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fieldValues: PropTypes.object.isRequired,
};

UberEatsPickupField.defaultProps = {
  error: null,
  loading: false,
};

export default UberEatsPickupField;
