import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Loading } from '../Loading';

const SubmitButton = ({
  className,
  loading,
  text,
  children,
  ...props
}) => (
  <Button className={`btn-submit ${className}`} disabled={loading} {...props}>
    {loading ? <Loading /> : (children || text)}
  </Button>
);

SubmitButton.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

SubmitButton.defaultProps = {
  loading: false,
  text: 'Submit',
  className: '',
  children: null,
};

export default SubmitButton;
