import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import PickupErrorImg from '../../images/pickup-error-modal.png';

const PickupErrorModal = ({
  toggleModal,
}) => (
  <Modal onClick={toggleModal} isOpen autoFocus={false} style={{ maxWidth: '700px' }} backdropClassName="pickup-error-modal-backdrop">
    <ModalBody>
      <img style={{ width: '100%' }} src={PickupErrorImg} />
    </ModalBody>
  </Modal>
);

PickupErrorModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
};

export default PickupErrorModal;
