import React from 'react';
import PropTypes from 'prop-types';
import { SubmitButton } from '../../../miscellaneous/Buttons';
import CustomerThumb from '../../../miscellaneous/CustomerThumb';
import { updateOrderDropOffForm } from '../../../../apollo';

const clickBack = () => updateOrderDropOffForm({
  input: { formStep: 'CUSTOMERPICKUP', searchCustomer: false, phoneNumberIsValid: null },
  fieldInput: { name: 'customerPhone', value: '' },
});

const clickForward = () => updateOrderDropOffForm({ input: { formStep: 'CUSTOMERPICKUP|2' } });

const ConfirmCustomer = ({ fieldValues: { customerName, customerPhone } }) => (
  <div className="container-fluid instruction-modal">
    <div className="row justify-content-center temp-class-verify-modal">
      <div className="col-12">
        <div className="summary-wrapper-new-order">
          <CustomerThumb name={customerName} phone={customerPhone} />
        </div>
      </div>
    </div>
    <div className="row justify-content-center" style={{ marginTop: '40px' }}>
      <div className="col">
        <SubmitButton text="Whoops!" onClick={clickBack} color="danger" />
        <SubmitButton text="It's good!" onClick={clickForward} />
      </div>
    </div>
  </div>
);

ConfirmCustomer.propTypes = {
  fieldValues: PropTypes.object.isRequired,
};

export default ConfirmCustomer;
