import React from 'react';
import PropTypes from 'prop-types';
import { SubmitButton } from '../miscellaneous/Buttons';
import ModalContent from '../miscellaneous/ModalContent';
import PrivacyPolicyContent from './PrivacyPolicyContent';

const PrivacyPolicy = ({ handleBack }) => (
  <ModalContent header="Privacy Policy">
    <div className="container-fluid">
      <PrivacyPolicyContent />
      <br />
      <SubmitButton color="danger" text="Back" onClick={handleBack} />
    </div>
  </ModalContent>
);

PrivacyPolicy.propTypes = {
  handleBack: PropTypes.func.isRequired,
};

export default PrivacyPolicy;
