import { gql } from '@apollo/client';

export default gql`
  query order($orderId: ID!){
    getOrder: order(id: $orderId) {
      id
      orderTotal
      isPaid
      customer {
        id
        name
      }
    }
  }
`;
