/* eslint camelcase: 0 */
export const nullFnc = () => null;

export const getMinutesSinceCreation = (dateTime) => {
  const minutesInMs = 1000 * 60;
  const currentDate = new Date().getTime();
  const createdDate = new Date(dateTime).getTime();
  const differenceInMins = Math.round((currentDate - createdDate) / minutesInMs);
  return `Dropped ${differenceInMins} min(s) ago`;
};

export const formatPhone = (phone) => {
  let formattedPhone = phone.replace(/\D/g, '');
  if (formattedPhone.length > 10) {
    formattedPhone = formattedPhone.slice(0, 10);
  }
  if (formattedPhone.length > 3) {
    formattedPhone = `(${formattedPhone.slice(0, 3)}) ${formattedPhone.slice(3)}`;
  }
  if (formattedPhone.length > 9) {
    formattedPhone = `${formattedPhone.slice(0, 9)}-${formattedPhone.slice(9)}`;
  }
  return formattedPhone;
};

export const formatOrderTotal = (value) => {
  let rawValue = value.replace(/\D/g, '');
  if (rawValue.length < 3) {
    rawValue = Array(4 - rawValue.length).join('0') + rawValue;
  }
  // parseFloat the part before ',' to remove leading zeros
  const floatValue = `${rawValue.slice(0, -2)}.${rawValue.slice(-2)}`;
  return parseFloat(floatValue).toFixed(2).toString(); // ? Better way?
};

export const generatePINCode = () => {
  let randomNumber = String(Math.floor(Math.random() * 9999));
  // Add leading zeros if length is less than 4 digits:
  while (randomNumber.length < (4 || 2)) { randomNumber = `0${randomNumber}`; }
  return randomNumber;
};

export const formatPasscode = str => str.replace(/\D/g, '').substring(0, 4);

export const prepareDropOffVariables = (formValues, runner_id) => {
  const {
    formStep,
    pickupService,
    pickupInputFieldsJSON,
    customerId,
    lockers,
  } = formValues;
  const currentInputs = JSON.parse(pickupInputFieldsJSON);
  const {
    customerName,
    isPaid,
    numOfItems,
    orderTotal,
    orderCode,
  } = currentInputs;

  const CreateOrder = { runner_id };

  if (/CUSTOMER/.test(formStep)) {
    CreateOrder.orderType = 'DEFAULT';
    CreateOrder.orderAI = {
      customer_id: customerId,
      orderTotal: parseFloat(orderTotal),
      isPaid,
    };
  }
  if (pickupService === 'DOORDASH' || pickupService === 'GRUBHUB') {
    CreateOrder.doorDashAI = { customerName, numOfItems: Number(numOfItems) };
  }
  if (pickupService === 'UBEREATS') {
    CreateOrder.uberEatsAI = { orderCode };
  }
  CreateOrder.orderType = CreateOrder.orderType || pickupService;
  return {
    CreateOrder,
    lockers,
    lockerPin: generatePINCode(),
    isOverflowItem: !lockers.length,
  };
};

export const getModalTitle = ({ formStep, pickupService, loading }) => {
  if (loading) return 'Creating your order...';
  switch (formStep) {
    case 'SELECTTYPE':
      return 'Who\'s this order for?';
    case 'CUSTOMERPICKUP':
      return 'Customer Search';
    case 'CUSTOMERPICKUP|1':
      return 'Verify Phone Number';
    case 'CUSTOMERPICKUP|2':
      return 'Order Total';
    case 'SERVICEPICKUP':
      return pickupService || 'Choose A Delivery Service';
    case 'PICKUPINFORMATION':
      return 'Box Information';
    default:
      return 'Error Retrieving Title';
  }
};
