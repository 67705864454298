import React from 'react';
import PropTypes from 'prop-types';
import Locker from '../Locker';

// TODO: Fix the styling, and maybe the member display info.

const OperationButton = ({ text, ...props }) => (
  <button className="locker-operation-button" type="button" {...props}>{text}</button>
);

const OverflowItemList = ({
  overflowItems,
  onClick,
  itemMethods,
  itemExpired,
}) => {
  const {
    // move,
    remove, // used to also confirm overflow item was picked up
    // edit,
  } = itemMethods;

  return overflowItems.map(item => (
    <div className="col-12 locker-list-item" key={item.id} style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
      <div className="row align-items-center">
        <div className="col">
          <Locker overflowItem={item} onClick={onClick} itemExpired={itemExpired} />
        </div>
        <div className="col text-right">
          {[{
            onClick: e => remove(e, item, { isOverflowItem: true }),
            text: 'Confirm',
            style: { backgroundColor: '#60D470' },
          }, {
            onClick: e => remove(e, item),
            text: 'Remove',
            style: { backgroundColor: '#F90021' },
          }].map(props => <OperationButton key={props.text} {...props} />)}
        </div>
      </div>
    </div>
  ));
};

OperationButton.propTypes = {
  text: PropTypes.string.isRequired,
};

OverflowItemList.propTypes = {
  onClick: PropTypes.func.isRequired,
  itemMethods: PropTypes.shape({
    move: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  itemExpired: PropTypes.bool,
};

OverflowItemList.defaultProps = {
  itemExpired: false,
};

export default OverflowItemList;
