import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { PICKUP_ORDER } from '../../apollo';
import UberEatsPickupFields from './UberEatsPickupFields';
import DoorDashPickupFields from './DoorDashPickupFields';

const DynamicServiceInput = ({ pickupService, setState, toggleErrorModal }) => {
  const [fieldValues, setFieldValues] = useState({});
  const [submit, { data, loading, error }] = useMutation(PICKUP_ORDER);
  if (data) {
    if (data.pickupOrder) {
      setState({ formStep: 'PICKUPINFORMATION', pickupInformation: data.pickupOrder });
    } else {
      toggleErrorModal(); 
    }
    return <div />;
  }

  const handleSubmit = orderType => submit({ variables: { orderType, accessInformation: JSON.stringify(fieldValues) } });
  const handleInput = ({ target: { name, value } }) => setFieldValues({ ...fieldValues, [name]: value.toUpperCase() });
  const fieldProps = {
    handleInput,
    fieldValues,
    handleSubmit,
    loading,
    error,
  };

  return (
    <div className="container-fluid delivery-service-input-form">
      <div className="row justify-content-center">
        {pickupService === 'UBEREATS' && <UberEatsPickupFields {...fieldProps} />}
        {/DOORDASH|GRUBHUB/.test(pickupService) && <DoorDashPickupFields pickupService={pickupService} {...fieldProps} />}
      </div>
    </div>
  );
};

DynamicServiceInput.propTypes = {
  pickupService: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  toggleErrorModal: PropTypes.func.isRequired,
};

export default DynamicServiceInput;
