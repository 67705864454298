import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Input } from 'reactstrap';
import { updateOrderDropOffForm } from '../../../../apollo';
import Keypad from '../../../miscellaneous/Keypad';
import CustomerThumb from '../../../miscellaneous/CustomerThumb';
import { formatOrderTotal } from '../../../../methods';

const OrderDetailsStep = ({
  orderTotalIsValid,
  orderTotal,
  isPaid,
  customerName,
  customerPhone,
  handleSubmit: parentHandleSubmit,
}) => {
  const handleSubmit = () => (
    !isPaid && (!parseFloat(orderTotal) || parseFloat(orderTotal) < 0.5)
      ? updateOrderDropOffForm({ input: { orderTotalIsValid: false } })
      : parentHandleSubmit()
  );
  const setFieldInput = fieldInput => updateOrderDropOffForm({ fieldInput });
  const handleKeypad = value => setFieldInput({ name: 'orderTotal', value: formatOrderTotal(`${orderTotal}${value}`) });
  const handleKeypadBack = () => {
    setFieldInput({
      name: 'orderTotal',
      value: formatOrderTotal(orderTotal.slice(0, -1)) || '0',
    });
  };
  const handleKeyboard = ({ target: { value } }) => setFieldInput({ name: 'orderTotal', value: formatOrderTotal(value) });
  const toggleIsPaid = () => setFieldInput({ name: 'isPaid', value: !isPaid });
  return (
    <div className="order-modal-phone-input">
      <div className="container-fluid">
        <CustomerThumb name={customerName} phone={customerPhone} />
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <Form onSubmit={parentHandleSubmit}>
            <div className="order-inputs-inline-total-paid-labels">
              <div>Order Total</div>
              <div>Is paid?</div>
            </div>
            <div className="order-inputs-inline-total-paid">
              <FormGroup>
                <Input
                  id="orderTotalField"
                  name="orderTotal"
                  className="large-input pin-input order-total-input"
                  value={orderTotal}
                  placeholder="0.00"
                  onChange={handleKeyboard}
                  readOnly
                  autoComplete="off"
                />
              </FormGroup>
              <div className="form-group checkbox-is-paid" onClick={toggleIsPaid}>
                <label htmlFor="isPaid">
                  <input
                    name="isPaid"
                    className="label__checkbox"
                    type="checkbox"
                    checked={isPaid}
                    readOnly
                  />
                  <span className="label__text">
                    <span className="label__check">
                      <i className={isPaid ? 'ion-checkmark-round icon' : 'ion-close-round dark icon'} />
                    </span>
                  </span>
                </label>
              </div>
            </div>
            {orderTotalIsValid === false && <span className="error-msg">Unpaid orders must be over .50¢!</span>}
          </Form>
        </div>
        <div className="row justify-content-center">
          <Keypad
            onClick={handleKeypad}
            lastRowFunc={[handleKeypadBack, handleKeypad, handleSubmit]}
          />
        </div>
      </div>
    </div>
  );
};

OrderDetailsStep.propTypes = {
  orderTotal: PropTypes.string.isRequired,
  orderTotalIsValid: PropTypes.bool,
  isPaid: PropTypes.bool.isRequired,
  customerName: PropTypes.string,
  customerPhone: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

OrderDetailsStep.defaultProps = {
  orderTotalIsValid: null,
  customerName: 'N/A',
};

export default OrderDetailsStep;
