import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PickupInstructions from './PickupInstructions';
import ConfirmationFinish from './ConfirmationFinish';
import NewCustomerStep from './NewCustomerStep';
import DynamicServiceInput from '../miscellaneous/DynamicServiceInput2';
import { LoadingModal } from '../miscellaneous/Loading';

const initialState = {
  formStep: 'SERVICEPICKUP',
  newCustomerName: '',
  pickupService: null,
  pickupInformation: null,
  accessCodeIsValid: null,
  loading: true,
};

const handlePickupTitle = (pickupType, pickupService, formStep) => {
  if (formStep === 'PICKUPINFORMATION') return 'Pickup Info';
  if (formStep === 'CUSTOMERPICKUP') return 'Enter your verification code';
  if (formStep === 'NEWCUSTOMER') return 'We see that it\'s your first time!';
  if (formStep === 'SERVICEPICKUP' && pickupService === null) return 'I Drive For:';
  if (formStep === 'CONFIRMFINISH') return 'Picked Up Order From Box(es)?';
  let title = 'I am a:';
  if (pickupType) title = pickupType === 'Service' ? 'Delivery Service' : pickupType;
  return pickupService || title;
};

const PickupModal = ({
  handleAnyClick,
  toggleModal,
  toggleErrorModal,
  pickupData,
  initialPickupService,
}) => {
  const [state, setState] = useState({ ...initialState, pickupService: initialPickupService });
  const {
    formStep,
    newCustomerName,
    pickupService,
    pickupInformation,
    pickupType,
    loading,
  } = state;

  useEffect(() => {
    if (pickupData) {
      setState({
        ...state,
        loading: false,
        pickupInformation: pickupData,
        formStep: pickupData.isNewCustomer ? 'NEWCUSTOMER' : 'PICKUPINFORMATION',
      });
    } else {
      setState({ ...state, loading: false });
    }
  }, []);

  const title = handlePickupTitle(pickupType, pickupService, formStep);
  const isFormStep = value => value === formStep;
  const setStateFnc = value => setState({ ...state, ...value });

  if (loading) return <LoadingModal handleAnyClick={handleAnyClick} />;

  return (
    <Modal onClick={handleAnyClick} isOpen autoFocus={false}>
      <ModalHeader toggle={isFormStep('PICKUPINFORMATION') ? null : toggleModal}>{title}</ModalHeader>
      <ModalBody>
        {isFormStep('SERVICEPICKUP') && <DynamicServiceInput pickupService={pickupService} setState={setStateFnc} toggleErrorModal={toggleErrorModal} />}
        {isFormStep('NEWCUSTOMER') && (
          <NewCustomerStep
            customerId={pickupInformation.customerId}
            newCustomerName={newCustomerName}
            setState={setStateFnc}
          />
        )}
        {isFormStep('PICKUPINFORMATION') && <PickupInstructions item={pickupInformation} setState={setStateFnc} />}
        {isFormStep('CONFIRMFINISH') && <ConfirmationFinish endPickup={toggleModal} setState={setStateFnc} />}
      </ModalBody>
    </Modal>
  );
};

PickupModal.propTypes = {
  pickupData: PropTypes.object,
  handleAnyClick: PropTypes.func,
  toggleModal: PropTypes.func.isRequired,
  toggleErrorModal: PropTypes.func.isRequired,
  initialPickupService: PropTypes.string,
};

PickupModal.defaultProps = {
  pickupData: null,
  handleAnyClick: null,
  initialPickupService: null,
};

export default PickupModal;
