import React from 'react';
import PropTypes from 'prop-types';

const GQLError = ({ error }) => {
  if (!error || typeof error !== 'object') return null;
  const { graphQLErrors, networkError } = error;
  let errMessage;
  if (graphQLErrors) errMessage = graphQLErrors[0].message;
  if (networkError) errMessage = 'There was an issue with communicating with the server.';
  return <span className="error-msg">{errMessage}</span>;
};

GQLError.propTypes = {
  error: PropTypes.object,
};

GQLError.defaultProps = {
  error: null,
};

export default GQLError;
