import React from 'react';
import PropTypes from 'prop-types';
import CustomerAvatar from '../../images/icons/customer_avatar_2.svg';

const CustomerThumb = ({ name, phone }) => (
  <div className="customer-thumb">
    <div className="label-wrapper">
      <img src={CustomerAvatar} alt="Customer" />
      <span>Customer</span>
    </div>
    <div className="details-list-wrapper">
      <ul className="details-list">
        <li className="major">
          <span>Name</span>
          <span className="value">{name}</span>
        </li>
        <li className="minor">
          <span>Phone</span>
          <span className="value">{phone}</span>
        </li>
      </ul>
    </div>
  </div>
);

CustomerThumb.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string.isRequired,
};

CustomerThumb.defaultProps = {
  name: 'N/A',
};

export default CustomerThumb;
