import React from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import Toggle from 'react-bootstrap-toggle';
import TooltipButton from './TooltipButton';
import RefreshIcon from '../../../images/icons/refresh_icon.png';

const OversizeCounter = Styled.span`
  font-size: 3rem;
  font-weight: bolder;
  color: purple;
  vertical-align: middle;
  width: 50px;
  box-sizing: content-box;
  flex: 1;
  cursor: pointer;
`;

const ToggleWrapper = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ButtonWrappers = Styled.div`
  display: flex;
  justify-content: space-evenly;
  flex: 1;
`;

const RefreshButton = ({ onClick }) => (
  <button id="refresh-button" onClick={onClick} type="button">
    <img className="lockers-refresh-button" src={RefreshIcon} alt="" />
  </button>
);

const Toolbar = ({
  refresh,
  isGridView,
  toggleView,
  oversizeCount,
}) => (
  <ToggleWrapper className="toggle-wrapper">
    <OversizeCounter onClick={isGridView ? toggleView : null}>{oversizeCount}</OversizeCounter>
    <Toggle
      onClick={toggleView}
      on={<p>Grid View</p>}
      off={<p>List View</p>}
      size="xs"
      offstyle="danger"
      active={isGridView}
    />
    <ButtonWrappers>
      <TooltipButton />
      <RefreshButton onClick={refresh} />
    </ButtonWrappers>
  </ToggleWrapper>
);

RefreshButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

Toolbar.propTypes = {
  refresh: PropTypes.func.isRequired,
  isGridView: PropTypes.bool.isRequired,
  toggleView: PropTypes.func.isRequired,
  oversizeCount: PropTypes.number.isRequired,
};

export default Toolbar;
