import { gql } from '@apollo/client';

export default gql`
  query {
    allReportTypes {
      id
      description
    }
  }
`;
