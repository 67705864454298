import React from 'react';
import PropTypes from 'prop-types';
import Locker from '../Locker';

// TODO: Fix the styling, and maybe the member display info.

const OperationButton = ({ text, ...props }) => (
  <button className="locker-operation-button" type="button" {...props}>{text}</button>
);

const LockerListView = ({
  lockers,
  onClick,
  itemMethods,
  itemExpired,
}) => {
  const lockersInUse = lockers.filter(item => item.status !== 'EMPTY');
  const {
    move,
    remove,
    pin,
  } = itemMethods;

  return lockersInUse.map(locker => (
    <div className="col-12 locker-list-item" key={locker.lockerNumber} style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
      <div className="row align-items-center">
        <div className="col">
          <Locker lockerData={locker} onClick={onClick} itemExpired={itemExpired} />
        </div>
        <div className="col text-right">
          {[{
            onClick: e => move(e, locker),
            text: 'Move',
            style: { backgroundColor: '#60D470' },
          }, {
            onClick: e => remove(e, locker),
            text: 'Remove',
            style: { backgroundColor: '#F90021' },
          }, {
            onClick: e => pin(e, locker),
            text: 'Pin',
            style: { backgroundColor: '#1E90FF' },
          }].map(props => <OperationButton key={props.text} {...props} />)}
        </div>
      </div>
    </div>
  ));
};

OperationButton.propTypes = {
  text: PropTypes.string.isRequired,
};

LockerListView.propTypes = {
  lockers: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  itemMethods: PropTypes.shape({
    move: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    pin: PropTypes.func.isRequired,
  }).isRequired,
  itemExpired: PropTypes.bool,
};

LockerListView.defaultProps = {
  itemExpired: false,
};

export default LockerListView;
