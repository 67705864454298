import { gql } from '@apollo/client';

export default gql`
  query {
    orderDropOffForm @client {
      lockers
      customerId
      orderTotalIsValid
      phoneNumberIsValid
      formStep
      searchCustomer
      pickupService
      pickupInputFieldsJSON
      submittedFormData
    }
  }
`;
