import React, { useState } from 'react';
import Styled from 'styled-components';
// import SignupModal from '../signup';
// import HelpModal from '../help';
import DeliveryPickupButton from './DeliveryPickupButton';
import PickupForm from './PickupForm';
import PickupModal from './PickupModal';
import PickupErrorModal from './PickupErrorModal';

const HomeScreenWrapper = Styled.div`
  display: flex;
  position: relative;
  flex: 1;
`;

const HomeScreenButtonsWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (max-width: 600px) {
    & {
      margin: 40px 0px;
    }
  }
`;

const modals = {
  // SignupModal,
  // HelpModal,
  PickupModal,
  PickupErrorModal,
};

const initialState = {
  modal: null,
  pickupData: null,
  pickupService: null,
  loading: false,
};

const StartScreen = () => {
  const [state, setState] = useState(initialState);
  const {
    modal,
    pickupData,
    pickupService,
    loading,
  } = state;
  const Modal = modals[modal];

  return (
    <HomeScreenWrapper>
      <HomeScreenButtonsWrapper>
        <PickupForm setState={value => setState({ ...state, ...value, pickupService: null })} />
        <DeliveryPickupButton setState={value => setState({ ...state, ...value, pickupData: null })} loading={loading} />
      </HomeScreenButtonsWrapper>
      {Modal && (
        <Modal
          toggleErrorModal={() => setState({ ...state, modal: 'PickupErrorModal' })}
          toggleModal={() => setState(initialState)}
          pickupData={pickupData}
          initialPickupService={pickupService}
        />
      )}
    </HomeScreenWrapper>
  );
};

export default StartScreen;
