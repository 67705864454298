import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Query, Mutation } from '@apollo/client';
import {
  // Form,
  // FormGroup,
  // Input,
  // Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
// import { GET_LOCKERS, MOVE_ORDER } from '../../../apollo';

class EditItemModal extends Component {
  constructor() {
    super();
    this.state = {
      // lockerTo: null,
    };
  }

  handleInput = e => this.setState({ [e.target.name]: parseInt(e.target.value, 10) });

  render() {
    const {
      lockerData,
      handleAnyClick,
      toggle,
      showModal,
    } = this.props;
    // const { lockerTo } = this.state;
    if (!lockerData) return false;

    return (
      <Modal onClick={handleAnyClick} className="input-item-modal" isOpen={showModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit Mail</ModalHeader>
        <ModalBody>
          FEATURE COMING SOON
          {/* <Query query={GET_LOCKERS}>
            {({ loading, qError, data: { lockers } }) => {
              if (loading) return <div />;
              if (qError) return `Error!: ${qError}`;
              const openLockers = lockers.lockers.filter(locker => locker.status === 'EMPTY');
              return (
                <div className="container-fluid">
                  <Mutation mutation={MOVE_ORDER} refetchQueries={[{ query: GET_LOCKERS }]}>
                    {(moveOrder, { mError, data }) => {
                      if (data) {
                        toggle();
                        return null;
                      }
                      return (
                        <Form onSubmit={(e) => {
                          e.preventDefault();
                          moveOrder({
                            variables: {
                              newLockerNumber: lockerTo || openLockers[0].lockerNumber,
                              oldLockerNumber: lockerData.lockerNumber,
                            },
                          });
                        }}
                        >
                          <FormGroup>
                            <Input name="lockerTo" type="select" onChange={this.handleInput}>
                              {openLockers.map(({ lockerNumber: x }) => (
                                <option key={x} value={x}>{x}</option>
                              ))}
                            </Input>
                          </FormGroup>
                          {mError && <div className={mError} />}
                          <Button
                            style={{ width: '100%', height: '40px' }}
                            type="submit"
                            className="btn-submit"
                          >
                            Switch
                          </Button>
                        </Form>
                      );
                    }}
                  </Mutation>
                </div>
              );
            }}
          </Query> */}
        </ModalBody>
      </Modal>
    );
  }
}

EditItemModal.propTypes = {
  lockerData: PropTypes.object.isRequired,
  handleAnyClick: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default EditItemModal;
