import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import {
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { GET_LOCKERS, MOVE_ORDER } from '../../../apollo';

const MoveItemModal = ({
  lockerData,
  handleAnyClick,
  toggle,
  showModal,
}) => {
  const { loading, qError, data: { lockers } } = useQuery(GET_LOCKERS);
  const [moveOrder, { mError, data }] = useMutation(MOVE_ORDER, { refetchQueries: [{ query: GET_LOCKERS }] });

  const [lockerTo, setState] = useState(null);
  const handleInput = e => setState(parseInt(e.target.value, 10));
  if (!lockerData) return false;
  if (loading) return <div />;
  if (qError) return `Error!: ${qError}`;
  if (data) {
    toggle();
    return null;
  }

  const modalTitle = `[${lockerData.lockerNumber}] To which locker?`;
  const openLockers = lockers.lockers.filter(locker => locker.status === 'EMPTY');

  return (
    <Modal onClick={handleAnyClick} className="input-item-modal" isOpen={showModal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <Form onSubmit={(e) => {
            e.preventDefault();
            moveOrder({
              variables: {
                newLockerNumber: lockerTo || openLockers[0].lockerNumber,
                oldLockerNumber: lockerData.lockerNumber,
              },
            });
          }}
          >
            <FormGroup>
              <Input name="lockerTo" type="select" onChange={handleInput}>
                {openLockers.map(({ lockerNumber: x, isDisabled }) => (
                  <option key={x} value={x} disabled={isDisabled}>
                    {`${x} ${isDisabled ? '[Disabled]' : ''}`}
                  </option>
                ))}
              </Input>
            </FormGroup>
            {mError && <div className={mError} />}
            <Button style={{ width: '100%', height: '40px' }} type="submit" className="btn-submit">Switch</Button>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};

MoveItemModal.propTypes = {
  lockerData: PropTypes.object.isRequired,
  handleAnyClick: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default MoveItemModal;
