import React from 'react';
import PropTypes from 'prop-types';
import InputItemModal from './InputItem';
import MoveItemModal from './MoveItemModal';
import EditItemModal from './EditItemModal';
import RemoveAndConfirmModal from './RemoveAndConfirmModal';
import GetLockerPinModal from './GetLockerPinModal';
import ReadOnlyModal from './ReadOnlyModal';
import DisableModal from './DisableModal';
import AssistanceModal from './AssistanceModal';

const modals = {
  InputItemModal,
  MoveItemModal,
  EditItemModal,
  RemoveAndConfirmModal,
  GetLockerPinModal,
  ReadOnlyModal,
  DisableModal,
  AssistanceModal,
};

const LockerModal = ({
  showThisModal,
  handleAnyClick,
  toggleModal,
  chosenLocker,
  isOverflowItem: pickupOverflowItem,
}) => {
  if (!showThisModal) return null;
  let lockerNumber;
  let item;
  let isOverflowItem;

  if (showThisModal !== 'AssistanceModal') ({ lockerNumber, item, isOverflowItem } = chosenLocker);

  const modal = (item || isOverflowItem) && showThisModal === 'InputItemModal' ? 'ReadOnlyModal' : showThisModal;
  const Modal = modals[modal];
  return (
    <Modal
      handleAnyClick={handleAnyClick}
      showModal={Boolean(showThisModal)}
      toggle={toggleModal}
      lockerData={chosenLocker}
      lockerNumber={lockerNumber}
      itemData={item || chosenLocker}
      pickupOverflowItem={pickupOverflowItem}
    />
  );
};

LockerModal.propTypes = {
  showThisModal: PropTypes.string,
  handleAnyClick: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  chosenLocker: PropTypes.object,
  isOverflowItem: PropTypes.bool,
};

LockerModal.defaultProps = {
  showThisModal: null,
  chosenLocker: {},
  isOverflowItem: false,
};

export default LockerModal;
