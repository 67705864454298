import { gql } from '@apollo/client';

export default gql`
  query Customer($phoneNumber: String!, $upsert: Boolean) {
    getCustomerByPhone: Customer(phoneNumber: $phoneNumber, upsert: $upsert) {
      id
      name
    }
  }
`;
