import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  Observable,
  split,
  ApolloProvider,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import cache from './cache';

const {
  REACT_APP_GRAPHQL_WS_HOST,
  REACT_APP_GRAPHQL_HOST,
  REACT_APP_APP_ENV,
} = process.env;

const httpLink = createHttpLink({
  uri: `${REACT_APP_GRAPHQL_HOST}/graphql`,
  credentials: 'same-origin',
});

const wsLink = new WebSocketLink({
  uri: REACT_APP_GRAPHQL_WS_HOST,
  options: {
    reconnect: true,
    connectionParams: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  },
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const ApolloWrapper = ({ children }) => {
  const [authToken, setAuthToken] = useState();
  const { getAccessTokenSilently } = useAuth0();

  const request = async (operation) => {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  };

  const requestLink = new ApolloLink((operation, forward) => (
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
  ));

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message }) => (
            console.log(`[GraphQL error]: Message: ${message}`)
          ));
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      requestLink,
      splitLink,
    ]),
    name: `The Yummy Box - ${REACT_APP_APP_ENV}`,
    cache,
  });

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      setAuthToken(token);
    })();
  }, []);

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

ApolloWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ApolloWrapper;
