import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Signup from './Signup';
import TermsOfService from '../signup/TermsOfService';
import PrivacyPolicy from '../signup/PrivacyPolicy';
import LastStep from './LastStep';
import { formatPhone } from '../../methods';

const initialState = {
  formValues: {},
  screen: 'front',
  skipAccessCode: true,
};

const PlainCustomerSignUp = () => {
  const [state, setState] = useState(initialState);
  const { formValues, screen, skipAccessCode } = state;

  const setScreen = screenValue => setState({ ...state, screen: screenValue });
  const handleReCaptcha = value => setState({ ...state, formValues: { ...formValues, captcha: value } });
  const handleChange = ({ target }) => {
    let value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.name === 'phone') value = formatPhone(value);
    setState({ ...state, formValues: { ...formValues, [target.name]: value } });
  };

  return (
    <div className="main-content-wrapper flex modal-embedded pc-signup">
      {screen === 'front' && (
        <Signup
          linkTo={setScreen}
          formValues={formValues}
          skipAccessCode={skipAccessCode}
          handleChange={handleChange}
          handleReCaptcha={handleReCaptcha}
        />
      )}
      {screen === 'privacy' && <PrivacyPolicy handleBack={() => setScreen('front')} />}
      {screen === 'tos' && <TermsOfService handleBack={() => setScreen('front')} />}
      {screen === 'laststep' && <LastStep />}
    </div>
  );
};

export default withRouter(PlainCustomerSignUp);
