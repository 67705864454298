import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_UNPAID_ORDER } from '../../apollo';
import { LoadingText } from '../miscellaneous/Loading';
import PaymentSection from './PaymentSection';
import { OrderContext } from '../contexts';
import NoMatch from '../404';

const PaymentPage = ({ match: { params: { orderId } } }) => {
  const { data: { getOrder } = {}, loading, error } = useQuery(GET_UNPAID_ORDER, { variables: { orderId } });
  if (loading) return <LoadingText active />;
  if (error) return 'Error retrieving order';
  if (!getOrder) return <NoMatch />;
  return (
    <OrderContext.Provider value={{ orderId, customerName: getOrder.customerInfo.name }}>
      <PaymentSection order={getOrder} />
    </OrderContext.Provider>
  );
};

PaymentPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default PaymentPage;
