import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import FormButton from './FormButton';

const BackButton = Styled(FormButton)`
  font-size: 32px;
  color: black;
  width: 100px;
`;

const KeyboardWrapper = Styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
  justify-content: center;
  padding-top: 50px;
  opacity: ${({ isLoading }) => (isLoading ? '.5' : '1')};
`;

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
const Keyboard = ({ handleClick, handleBack, loading }) => (
  <KeyboardWrapper isLoading={loading}>
    {alphabet.map(letter => (
      <FormButton
        key={letter}
        onClick={() => handleClick(letter)}
        disabled={loading}
      >
        {letter}
      </FormButton>
    ))}
    <BackButton onClick={handleBack} disabled={loading}>Back</BackButton>
  </KeyboardWrapper>
);

Keyboard.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Keyboard.defaultProps = {
  loading: false,
};

export default Keyboard;
