import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useQuery, useMutation } from '@apollo/client';
import { getModalTitle, prepareDropOffVariables } from '../../../../methods';
import SelectPickupType from '../../../miscellaneous/SelectPickupType';
import SelectPickupService from '../../../miscellaneous/SelectPickupService';
import DynamicServiceInput from '../../../miscellaneous/DynamicServiceInput';
import CustomerDropOffForm from './CustomerDropOffForm';
import {
  CREATE_ORDER,
  GET_LOCKERS as query,
  ORDER_DROP_OFF_FORM,
  updateOrderDropOffForm,
} from '../../../../apollo';
import { RunnerContext } from '../../../contexts';
import Confirmation from './Confirmation';
import { Loading } from '../../../miscellaneous/Loading';

const DeliveryServiceDropOffForm = ({ pickupService, ...props }) => (
  pickupService
    ? (
      <DynamicServiceInput
        reactiveVar={updateOrderDropOffForm}
        pickupService={pickupService}
        {...props}
      />
    )
    : <SelectPickupService reactiveVar={updateOrderDropOffForm} />
);

const InputOrderModal = ({
  showModal,
  toggle,
  handleAnyClick,
  currentRunner,
}) => {
  const { data: { orderDropOffForm } } = useQuery(ORDER_DROP_OFF_FORM);
  const [submit, { loading }] = useMutation(CREATE_ORDER, { refetchQueries: [{ query }] });
  const {
    formStep,
    pickupService,
    pickupInputFieldsJSON,
    submittedFormData,
  } = orderDropOffForm;

  const modalTitle = getModalTitle({ formStep, pickupService, loading });
  const fieldValues = JSON.parse(pickupInputFieldsJSON);
  const resetFormAndToggle = () => {
    updateOrderDropOffForm({ closeForm: true });
    toggle();
  };
  const handleSubmit = () => {
    const variables = prepareDropOffVariables(orderDropOffForm, currentRunner.id);
    submit({ variables }).then(({ data: { createOrder } }) => (
      createOrder
        ? (
          updateOrderDropOffForm({
            input: {
              submittedFormData: JSON.stringify(variables),
              formStep: 'PICKUPINFORMATION',
            },
          })
        )
        : null
    ));
  };
  return (
    <Modal
      className="input-item-modal keypad-modal"
      onClick={handleAnyClick}
      isOpen={loading || showModal}
      toggle={loading ? null : resetFormAndToggle}
    >
      <ModalHeader toggle={loading ? null : resetFormAndToggle}>{modalTitle}</ModalHeader>
      <ModalBody>
        {loading && <Loading />}
        {!loading && formStep === 'SELECTTYPE' && <SelectPickupType reactiveVar={updateOrderDropOffForm} />}
        {!loading && /CUSTOMERPICKUP/.test(formStep) && (
          <CustomerDropOffForm
            fieldValues={fieldValues}
            orderDropOffForm={orderDropOffForm}
            handleSubmit={handleSubmit}
          />
        )}
        {!loading && formStep === 'SERVICEPICKUP' && (
          <DeliveryServiceDropOffForm
            fieldValues={fieldValues}
            pickupService={pickupService}
            handleSubmit={handleSubmit}
          />
        )}
        {formStep === 'PICKUPINFORMATION' && (
          <Confirmation
            fieldValues={fieldValues}
            submittedFormData={submittedFormData}
            closeModal={resetFormAndToggle}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

const InputOrderModalWithContext = ({ ...props }) => (
  <RunnerContext.Consumer>
    {value => <InputOrderModal currentRunner={value} {...props} />}
  </RunnerContext.Consumer>
);

DeliveryServiceDropOffForm.propTypes = {
  pickupService: PropTypes.string,
  fieldValues: PropTypes.object.isRequired,
};

DeliveryServiceDropOffForm.defaultProps = {
  pickupService: '',
};

InputOrderModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  currentRunner: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  handleAnyClick: PropTypes.func.isRequired,
};

export default InputOrderModalWithContext;
