import React from 'react';
import Styled from 'styled-components';
import PaymentSuccessIcon from '../../images/icons/payment_success.svg';

const SuccessWrapper = Styled.div`
  padding: 50px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  margin: 30px auto;

  & img {
    max-width: 100px;
    max-height: 100px;
  }

  & h2 {
    font-weight: 800;
    letter-spacing: 1px;
    margin-top: 30px;
    color: #474747;
    text-transform: uppercase;
  }

  @media all and (max-width: 660px) {
    & h2 {
      font-size: 0.9rem;
    }
  }
`;

const SuccessfulPayment = () => (
  <SuccessWrapper className="container">
    <img src={PaymentSuccessIcon} alt="Thank You!" />
    <h2>Thank You</h2>
  </SuccessWrapper>
);

export default SuccessfulPayment;
