import React from 'react';

const map = new Array(12).fill();
const Loading = () => (
  <div className="sk-circle">
    {map.map((v, i) => <div key={i} className={`sk-circle${i + 1} sk-child`} />)}
  </div>
);

export default Loading;
