import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { useMutation, useLazyQuery } from '@apollo/client';
import { ASK_FOR_ASSISTANCE, CUSTOMER_NEEDS_ASSISTANCE } from '../../apollo';
import FormButton from './FormButton';
import { Loading } from '../miscellaneous/Loading';
import { BusinessContext } from '../contexts';

const AssistanceButton = Styled(FormButton)`
  height: 50px;
  width: ${({ isHelpRequested }) => isHelpRequested ? '380' : '320'}px;
  color: black;
  font-size: ${({ isHelpRequested }) => isHelpRequested ? '20' : '28'}px;
`;

// NOTE Find out what to do with errors here?
const AskForHelpButton = ({ businessId }) => {
  const [{ isHelpRequested, initialCheck }, setState] = useState({
    isHelpRequested: false,
    initialCheck: false,
  });
  const [submit, { loading: mLoading }] = useMutation(ASK_FOR_ASSISTANCE, { variables: { businessId } });

  const [checkBusiness, {
    loading: qLoading,
    data: {
      currentBusiness: { customerNeedsAssistance = true } = {},
    } = {},
  }] = useLazyQuery(CUSTOMER_NEEDS_ASSISTANCE, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let timeout;
    if (qLoading) return ;
    if (isHelpRequested) {
      if (!initialCheck) {
        checkBusiness();
        setState({ isHelpRequested, initialCheck: true });
      } else if (!customerNeedsAssistance) {
        // customerNeedsAssistance will be false for all subsequent flows,
        // so need to wait on value to propagate
        timeout = setTimeout(() => setState({ isHelpRequested: false, initialCheck: false }), 1000);
      } else {
        timeout = setInterval(checkBusiness, 5000);
      }
    }
    return () => { clearInterval(timeout); clearTimeout(timeout); };
  }, [isHelpRequested, initialCheck, customerNeedsAssistance, qLoading]);

  const isLoading = qLoading || mLoading;
  const content = isHelpRequested ? 'Help Requested! / Solicitando ayuda' : 'Ask For Help / Ayuda';
  const handleSubmit = () => {
    submit().then(() => setState({ isHelpRequested: true, initialCheck }));
  };

  return (
    <AssistanceButton isHelpRequested={isHelpRequested} onClick={handleSubmit} disabled={isLoading || isHelpRequested}>
      {isLoading ? <Loading /> : content}
    </AssistanceButton>
  );
};

const AskForHelpButtonWrapper = () => (
  <BusinessContext.Consumer>
    {({ id }) => <AskForHelpButton businessId={id} />}
  </BusinessContext.Consumer>
);

AskForHelpButton.propTypes = {
  businessId: PropTypes.string.isRequired,
};

export default AskForHelpButtonWrapper;
