import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmTimer from '../miscellaneous/ConfirmTimer';
import { SubmitButton } from '../miscellaneous/Buttons';
import ThankYouImg from '../../images/yummy-thank-you.png';

const ConfirmationFinish = ({ endPickup, setState }) => {
  const [isThankYouScreen, switchScreen] = useState(false);

  useEffect(() => {
    const timeout = isThankYouScreen && setTimeout(endPickup, 3000);
    return () => clearTimeout(timeout);
  });

  if (isThankYouScreen) {
    return (
      <span onClick={endPickup}>
        <img src={ThankYouImg} alt="Thank You Graphic" style={{ width: '100%' }} />
        <style dangerouslySetInnerHTML={{ __html: '.modal-header {display: none;}' }} />
      </span>
    );
  }
  return (
    <div className="container-fluid justify-content-center instruction-modal">
      <SubmitButton text="No!" onClick={() => setState({ formStep: 'PICKUPINFORMATION' })} color="danger" />
      <SubmitButton text="Yes!" onClick={() => switchScreen(true)} />
      <ConfirmTimer limit={10} timerEnds={() => switchScreen(true)} />
    </div>
  );
};

ConfirmationFinish.propTypes = {
  endPickup: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};

export default ConfirmationFinish;
