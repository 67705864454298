import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Timer from '../miscellaneous/Timer';

const boxColor = color => ({ backgroundColor: color, borderColor: color });
const style = {
  EMPTY: boxColor('#60D470'),
  UNCLAIMED: boxColor('#C7D9DE'),
  CLAIMED: boxColor('#008FF8'),
  OVERFLOW: boxColor('purple'),
  EXPIRED: boxColor('#FAB12D'),
};

const isFresh = (item) => {
  let isThisFresh = true;
  if (item && item.createdAt) {
    const elapsedTime = Math.floor((new Date() - new Date(item.createdAt)) / 60000);
    if (elapsedTime > 20) isThisFresh = false;
  }
  return isThisFresh;
};

const getButtonStyle = (item) => {
  if (!item) return style.EMPTY;
  if (!isFresh(item)) return style.EXPIRED;
  if (item.isOverflowItem) return style.OVERFLOW;
  if (!item.customerIsAware) return style.UNCLAIMED;
  if (item.customerIsAware) return style.CLAIMED;
  return boxColor('red');
};

const Locker = ({
  onClick,
  onLongClick,
  lockerData,
  overflowItem,
  selected,
}) => {
  const [isCommandHandled, setIsCommandHandled] = useState(null);
  const [buttonPressTimer, setButtonPressTimer] = useState(null);

  const handleButtonPress = (e) => {
    if (!lockerData.item) {
      if (buttonPressTimer) clearTimeout(buttonPressTimer);
      const timeout = setTimeout(() => {
        onLongClick(e, lockerData);
        // setIsCommandHandled(true);
      }, 1000);

      setButtonPressTimer(timeout);
    }
    setIsCommandHandled(false);
  };

  const handleButtonRelease = (e, itemData) => {
    if (!isCommandHandled && !lockerData.isDisabled) {
      onClick(e, itemData);
      setIsCommandHandled(true);
    }
    clearTimeout(buttonPressTimer);
  };

  if (!lockerData && !overflowItem) return null;

  let lockerNumber;
  let createdAt;
  let lockerLabel;
  let orderType;
  let className = 'lockerButton';
  if (selected) className += ' selected';

  if (lockerData) {
    const {
      status,
      item,
      isDisabled,
    } = lockerData;
    if (isDisabled) className += ' disabled-locker';

    ({ orderType } = lockerData);
    createdAt = status !== 'EMPTY' && item ? item.createdAt : null;
    ({ lockerNumber } = lockerData);
    lockerLabel = <span className="number">{lockerNumber}</span>;
  } else {
    const fontSize = '1rem';

    ({ createdAt, orderType } = overflowItem);
    lockerNumber = 'O.F./O.S.';
    lockerLabel = (
      <>
        <span className="number" style={{ fontSize, top: '0' }}>Overflow/</span>
        <span className="number" style={{ fontSize, top: '20px' }}>Oversize</span>
      </>
    );
  }

  if (orderType === 'UBEREATS') className += ' locker-ubereats';
  if (orderType === 'DOORDASH') className += ' locker-doordash';
  if (orderType === 'GRUBHUB') className += ' locker-grubhub';

  return (
    <Button
      className={className}
      style={getButtonStyle(lockerData ? lockerData.item : overflowItem)}
      onMouseDown={handleButtonPress}
      onTouchStart={handleButtonPress}
      onMouseUp={e => handleButtonRelease(e, lockerData || overflowItem)}
      onTouchEnd={e => handleButtonRelease(e, lockerData || overflowItem)}
    >
      {lockerLabel}
      <span className="handle" />
      {createdAt && (
        <Timer
          startTime={createdAt}
          limit={25}
          lockerNumber={lockerNumber}
        />
      )}
    </Button>
  );
};

Locker.propTypes = {
  onClick: PropTypes.func.isRequired,
  onLongClick: PropTypes.func,
  lockerData: PropTypes.object,
  overflowItem: PropTypes.object,
  selected: PropTypes.bool,
};

Locker.defaultProps = {
  onLongClick: null,
  lockerData: null,
  overflowItem: null,
  selected: false,
};

export default Locker;
