import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { useMutation } from '@apollo/client';
// import QRScanner from 'qr-code-scanner';
import { PICKUP_ORDER } from '../../apollo';
import Keyboard from './Keyboard';
import AskForHelpButton from './AskForHelpButton';

const CustomerPickupWrapper = Styled.div`
  flex: 1;
  text-align: center;
  color: white;
  background-color: black;
  padding-top: 20px;

  & h1 {
    font-family: "Open Sans", sans-serif;
  }
`;

const TextInput = Styled.input.attrs({ type: 'text' })`
  height: 100px;
  width: 400px;
  color: red;
  font-weight: bold;
  font-size: 80px;
  font-family: 'Fredoka One', cursive;
  text-align: center;

  &:disabled {
    background-color: white;
  }
`;

const BottomButtonsWrapper = Styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    & {
      flex-direction: column;
      align-items: center;
    }
  }
`;

const ErrorMessage = Styled.div`
  height: 100px;
  width: 400px;
  color: red;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Fredoka One', cursive;
  text-align: center;
  background-color: white;
  text-align: center;
  padding: 20px 0;
  margin: 0 auto;
`;

const ClientPickupForm = ({ setState }) => {
  const [handleSubmit, { loading, error }] = useMutation(PICKUP_ORDER);
  const [text, setText] = useState('');
  const [showError, setError] = useState(false);
  const handleClick = (letter) => {
    if (text.length < 4) {
      setError(false);
      setText(`${text}${letter}`);
    }
  };
  const handleBack = () => {
    setText(text.slice(0, -1));
    setError(false);
  };

  useEffect(() => {
    if (text.length === 4) {
      const accessInformation = JSON.stringify({ accessCode: text });
      setState({ loading: true })
      handleSubmit({ variables: { orderType: 'DEFAULT', accessInformation } })
        .then(({ data }) => {
          if (data.pickupOrder) {
            setState({ modal: 'PickupModal', pickupData: data.pickupOrder, loading: false });
          } else {
            setState({ modal: 'PickupErrorModal', loading: false })
          }
          setText('');
        });
    }
  }, [text]);

  useEffect(() => {
    let timeout;
    if (error) {
      setError(true);
      setText('');
      timeout = setTimeout(() => setError(false), 5000);
      return () => clearTimeout(timeout);
    }
  }, [error]);

  return (
    <CustomerPickupWrapper>
      <h1 style={{ fontSize: '3rem' }}>Customer</h1>
      <p>Enter Your Verification Code<br/>Imprima su código de verificación</p>
      {
        !(error && showError)
          ? <TextInput value={text} disabled={loading} readOnly />
          : (
            <ErrorMessage>
              Invalid Verification Code or
              <br />
              order was picked up
            </ErrorMessage>
          )
      }
      <div>We texted the phone associated with the order.</div>
      <Keyboard {...{ loading, handleClick, handleBack }} />
      <BottomButtonsWrapper>
        <AskForHelpButton />
      </BottomButtonsWrapper>
    </CustomerPickupWrapper>
  );
};

ClientPickupForm.propTypes = {
  setState: PropTypes.func.isRequired,
};

export default ClientPickupForm;
