import React from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { SubmitButton } from '../miscellaneous/Buttons';
import { orderDropOffFormVar } from '../../apollo';

const ButtonContainer = Styled.div`
  margin: 20px 0 0;
  transition: 500ms;
  overflow: hidden;
  padding: 0;

  &.inactive {
    height: 0;
  }
  &.active {
    height: 40px;
  }

  & .btn.overflow-button {
    background-color: purple;
    border-color: purple;
  }
`;

const CreateReservationButton = ({ onClick }) => {
  const { lockers } = useReactiveVar(orderDropOffFormVar);
  const isOverflowButton = !lockers.length;

  return (
    <ButtonContainer className="col justify-content-center">
      <SubmitButton
        onClick={onClick}
        className={isOverflowButton ? 'overflow-button' : 'reservation-button'}
        text={isOverflowButton ? 'Overflow/Oversize' : 'Make Order'}
      />
    </ButtonContainer>
  );
};

CreateReservationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CreateReservationButton;
