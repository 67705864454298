import React from 'react';
import { CardElement } from 'react-stripe-elements';

const createOptions = (fontSize, padding) => ({
  style: {
    base: {
      fontSize,
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
      padding,
    },
    invalid: {
      color: '#9e2146',
    },
  },
});

const CardSection = () => (
  <label style={{ width: '100%', margin: '20px 0 40px' }}>
    <CardElement {...createOptions('18px')} />
  </label>
);

export default CardSection;
