import React from 'react';
import PropTypes from 'prop-types';
import KeypadRow from './KeypadRow';
import { nullFnc } from '../../../methods';

const Keypad = ({
  firstRow,
  secondRow,
  thirdRow,
  onClick,
  lastRowFunc,
  lastRow,
}) => (
  <div className="keypad">
    {[firstRow, secondRow, thirdRow].map(row => (
      <div key={row} className="row justify-content-center">
        <KeypadRow row={row} onClick={onClick} />
      </div>
    ))}
    <div className="row justify-content-center">
      <KeypadRow row={lastRow} lastRowFunc={lastRowFunc} />
    </div>
  </div>
);

Keypad.propTypes = {
  firstRow: PropTypes.array,
  secondRow: PropTypes.array,
  thirdRow: PropTypes.array,
  onClick: PropTypes.func,
  lastRowFunc: PropTypes.array,
  lastRow: PropTypes.array,
};

Keypad.defaultProps = {
  firstRow: [1, 2, 3],
  secondRow: [4, 5, 6],
  thirdRow: [7, 8, 9],
  onClick: nullFnc,
  lastRow: ['Back', 0, 'Submit'],
  lastRowFunc: [nullFnc, nullFnc, nullFnc],
};

export default Keypad;
