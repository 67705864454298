import { gql } from '@apollo/client';

export default gql`
  query Runner($pin: String!) {
    getRunner: YummyRunner(pin: $pin) {
      id
      name
    }
  }
`;
