import { gql } from '@apollo/client';

export default gql`
  mutation pickupOrder($orderType: OrderType!, $accessInformation: String!) {
    pickupOrder(orderType: $orderType, accessInformation: $accessInformation) {
      lockerNumbers
      lockerPin
      id: orderId
      isPaid
      customerId
      isNewCustomer
    }
  }
`;
