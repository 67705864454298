import Styled from 'styled-components';

const FormButton = Styled.button.attrs({ type: 'button' })`
  background-color: red;
  width: 80px;
  height: 80px;
  margin: 0 20px 20px;
  color: white;
  border-color: red;
  font-family: 'Fredoka One';
  font-size: 68px;
  line-height: 0;
`;

export default FormButton;
