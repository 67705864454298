import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Label, Button } from 'reactstrap';
import CustomerThumb from '../../../miscellaneous/CustomerThumb';
import OrderThumb from '../../../miscellaneous/OrderThumb';
import { updateOrderDropOffForm } from '../../../../apollo';
import BinocularsIcon from '../../../../images/icons/binoculars.png';

// const UberEatsCard = ({ orderCode }) => (
//   <div className="summary-wrapper-new-order">
//     <CustomerThumb name={orderCode} phone="" />
//   </div>
// );

// const DoorDashCard = ({ customerName, numOfItems }) => (
//   <div className="summary-wrapper-new-order">
//     <CustomerThumb name={customerName} phone={String(numOfItems)} />
//   </div>
// );

const PinBar = ({ style, lockerPin }) => {
  const [isHidden, hidePin] = useState(false);
  useEffect(() => {
    if (!isHidden && lockerPin) {
      const timer = setTimeout(() => hidePin(true), 3000);
      return () => clearTimeout(timer);
    }
  });

  return (
    <div className="pin-code-field-wrapper row justify-content-center" style={{ paddingTop: '10px' }}>
      <Label for="pinBelow" className="large-label">PIN</Label>
      <span className="pin-lg-value" style={style} onClick={() => hidePin(false)}>
        {isHidden ? <img src={BinocularsIcon} alt="Binoculars" /> : (lockerPin || 'Overflow/Oversize')}
      </span>
    </div>
  );
};

const CustomerCards = ({
  customerPhone,
  customerName,
  orderTotal,
  isPaid,
}) => (
  <div className="summary-wrapper-new-order">
    <CustomerThumb name={customerName} phone={customerPhone} />
    <br />
    <OrderThumb orderTotal={orderTotal} isPaid={isPaid} />
  </div>
);

const Confirmation = ({ submittedFormData, closeModal, fieldValues }) => {
  const {
    CreateOrder: {
      // doorDashAI,
      // uberEatsAI,
      orderAI,
    },
    lockerPin,
    lockers,
    isOverflowItem = false,
  } = JSON.parse(submittedFormData);
  const valueStyle = isOverflowItem ? { letterSpacing: '3px', fontSize: '2rem' } : null;
  return (
    <div className="container-fluid">
      {/* {doorDashAI && <DoorDashCard {...doorDashAI} />} */}
      {/* {uberEatsAI && <UberEatsCard {...uberEatsAI} />} */}
      {orderAI && <CustomerCards {...fieldValues} />}
      <div className="pin-code-field-wrapper row justify-content-center" style={{ paddingBottom: '10px' }}>
        <Label for="lockerNumberBelow" className="large-label">YUMMY BOX</Label>
        <span className="pin-lg-value" style={valueStyle}>{isOverflowItem ? 'N/A' : lockers.join(',')}</span>
      </div>
      <PinBar style={valueStyle} lockerPin={isOverflowItem ? null : lockerPin} />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            <Button
              style={{ width: '100%', height: '70px' }}
              type="button"
              className="btn-submit"
              onClick={() => {
                updateOrderDropOffForm({ closeForm: true });
                closeModal();
              }}
            >
              Got It!
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

PinBar.propTypes = {
  style: PropTypes.object,
  lockerPin: PropTypes.any,
};

PinBar.defaultProps = {
  style: null,
  lockerPin: null,
};

CustomerCards.propTypes = {
  customerPhone: PropTypes.string,
  customerName: PropTypes.string,
  orderTotal: PropTypes.number,
  isPaid: PropTypes.bool,
};

CustomerCards.defaultProps = {
  customerPhone: '',
  customerName: '',
  orderTotal: '',
  isPaid: true,
};

Confirmation.propTypes = {
  submittedFormData: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  fieldValues: PropTypes.object.isRequired,
};

export default Confirmation;
