import React from 'react';
import { Elements } from 'react-stripe-elements';
import { useMutation } from '@apollo/client';
import { PAY_FOR_ORDER } from '../../apollo';
import SuccessfulPayment from './SuccessfulPayment';
import InjectedCheckoutForm from './CheckoutForm';

const MyStoreCheckout = () => {
  const [submit, { data, loading, error }] = useMutation(PAY_FOR_ORDER);
  if (loading) return 'loading';
  if (data && data.payForOrder) return <SuccessfulPayment />;
  return (
    <Elements>
      <InjectedCheckoutForm submitToken={submit} error={error} />
    </Elements>
  );
};

export default MyStoreCheckout;
