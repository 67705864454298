import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import InstructionContainer from './InstructionContainer';
import ConfirmTimer from '../miscellaneous/ConfirmTimer';
import { SubmitButton } from '../miscellaneous/Buttons';
import TwistRight from '../../images/openLock.png';
import PullOpen from '../../images/openLock2.png';

const PickupImagesContainer = Styled.div`
  display: flex;
  margin: 15px 0;

  & img {
    width: 250px;
    max-width: 100%;
  }
`;

const ImageDivider = Styled.span`
  background-color: black;
  border: 1px solid black;
  width: 6px;
`;

const PickupInstructions = ({ item: { lockerNumbers, lockerPin }, setState }) => (
  <div className="container-fluid instruction-modal">
    <InstructionContainer
      step="1"
      text="Locate the following Yummy Box(es) to get your order."
      information={lockerNumbers.join(', ')}
    />
    <InstructionContainer
      step="2"
      text="Unlock locker(s) with your unique code:"
      information={lockerPin}
    />
    <InstructionContainer step="3" text="Open the box and have a yummy one.">
      <PickupImagesContainer>
        <div>
          <img src={TwistRight} alt="" />
        </div>
        <ImageDivider />
        <div>
          <img src={PullOpen} alt="" />
        </div>
      </PickupImagesContainer>
      <p className="see-u">The Yummy Box lockers do not open automatically. To open it follow the steps from the above instruction</p>
    </InstructionContainer>
    <SubmitButton color="danger" onClick={() => setState({ formStep: 'CONFIRMFINISH' })} style={{ width: '100%' }}>
      Finished
    </SubmitButton>
    <ConfirmTimer limit={40} timerEnds={() => setState({ formStep: 'CONFIRMFINISH' })} />
  </div>
);

PickupInstructions.propTypes = {
  item: PropTypes.shape({
    lockerNumbers: PropTypes.array.isRequired,
    lockerPin: PropTypes.string.isRequired,
  }).isRequired,
  setState: PropTypes.func.isRequired,
};

export default PickupInstructions;
