import React from 'react';
import PropTypes from 'prop-types';
import StepsNav from './StepsNav';
import OrderDetailsStep from './OrderDetailsStep';
import PhoneInputStep from './PhoneInputStep';
import ConfirmCustomer from './ConfirmCustomer';

const CustomerDropOffForm = ({ handleSubmit, orderDropOffForm, fieldValues }) => {
  const {
    formStep,
    orderTotalIsValid,
    phoneNumberIsValid,
    searchCustomer,
  } = orderDropOffForm;
  const { customerPhone } = fieldValues;
  return (
    <>
      {formStep === 'CUSTOMERPICKUP' && (
        <PhoneInputStep phoneNumberIsValid={phoneNumberIsValid} customerPhone={customerPhone} searchCustomer={searchCustomer} />
      )}
      {formStep === 'CUSTOMERPICKUP|1' && <ConfirmCustomer fieldValues={fieldValues} />}
      {formStep === 'CUSTOMERPICKUP|2' && (
        <OrderDetailsStep {...fieldValues} orderTotalIsValid={orderTotalIsValid} handleSubmit={handleSubmit} />
      )}
      <StepsNav formData={orderDropOffForm} />
    </>
  );
};

CustomerDropOffForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  orderDropOffForm: PropTypes.object.isRequired,
  fieldValues: PropTypes.object.isRequired,
};

export default CustomerDropOffForm;
