import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Keypad from '../miscellaneous/Keypad';
import { GET_RUNNER } from '../../apollo';

const initialState = {
  passCode: '',
  isValid: null,
  runner: null,
};

const RunnerLoginModal = ({ handleAnyClick, toggleModal }) => {
  const [state, setState] = useState(initialState);
  const { isValid, runner, passCode } = state;

  const handleInput = e => setState({ passCode: e.target.value });
  const handleButton = value => setState({ passCode: `${passCode}${value}` });
  const handleBksp = () => setState({ passCode: passCode.slice(0, -1) });
  const [query, { data, loading }] = useLazyQuery(GET_RUNNER, {
    fetchPolicy: 'no-cache',
    variables: { pin: passCode },
  });

  const handleSubmit = () => {
    if (isValid || loading) return;
    query({ variables: { pin: passCode } });
  };

  useEffect(() => {
    if (loading) return;
    if (!loading && data && !data.getRunner) {
      setState({ passCode: '', isValid: false });
    } else if (data?.getRunner) {
      setState({ isValid: true, runner: data.getRunner });
    }
  }, [loading, data]);

  if (isValid) return <Redirect to={{ pathname: '/locker', state: { runner } }} />;

  return (
    <Modal
      onClick={handleAnyClick}
      className="input-item-modal keypad-modal"
      toggle={toggleModal}
      isOpen
    >
      <ModalHeader toggle={toggleModal}>Login</ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row justify-content-center">
            {isValid === false
              && <span className="error-msg">Invalid login data!</span> }
            <Form className="runner-login-form" onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="passCodeField" className="large-label faint">Enter In Passcode</Label>
                <Input
                  id="passCodeField"
                  type="password"
                  name="passCode"
                  value={passCode}
                  onChange={handleInput}
                  valid={false}
                  autoFocus
                  readOnly
                />
                <FormFeedback>That is not a valid pin</FormFeedback>
              </FormGroup>
            </Form>
          </div>
          <div className="row justify-content-center">
            <Keypad
              onClick={handleButton}
              lastRowFunc={[handleBksp, handleButton, handleSubmit]}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

RunnerLoginModal.propTypes = {
  handleAnyClick: PropTypes.func,
  toggleModal: PropTypes.func.isRequired,
};

RunnerLoginModal.defaultProps = {
  handleAnyClick: null,
};

export default RunnerLoginModal;
