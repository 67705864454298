import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getMinutesSinceCreation } from '../../methods';

/* INTERVAL: Currently the timer interval is 10 sec to throttle */
const Timer = ({ startTime }) => {
  const [time, setTime] = useState(getMinutesSinceCreation(startTime));

  useEffect(() => {
    const updateTime = setInterval(() => setTime(getMinutesSinceCreation(startTime)), 60000);

    return () => clearInterval(updateTime);
  }, []);

  return <div className="timer-cmp"><span>{time}</span></div>;
};

Timer.propTypes = {
  startTime: PropTypes.string.isRequired,
};

Timer.defaultProps = {
};

export default Timer;
