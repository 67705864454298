import React from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RegisterStripe } from './RegisterStripe';
import TYB_LOGO from '../images/logo/TYB_logo_header2.svg';
import { BusinessContext } from './contexts';

const StartScreenOuterStyle = Styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`;

const StartScreenButton = Styled(Link)`
  width: 70vw;
  min-width: 250px;
  max-width: 700px;
  background: #F03434;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 80px;
  border-radius: 40px;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-decoration: none;
  box-shadow: 0px 5px 15px -5px rgba(0,0,0,0.75);

  &:hover {
    color: white;
    text-decoration: none;
  }
`;

const MainScreenLogo = Styled.img`
  max-height: 40vh;
  max-width: 40vw;
  margin-bottom: 10vh;
`;

const StartScreen = ({ currentBusiness, location: { search } = {} }) => (
  <StartScreenOuterStyle>
    <MainScreenLogo src={TYB_LOGO} alt="The Yummy Box" />
    {
      !currentBusiness.stripeEnabled
        ? <RegisterStripe queryString={search} />
        : (
          <>
            <StartScreenButton to="/home">Get started</StartScreenButton>
            <h1>{currentBusiness.name}</h1>
          </>
        )
    }
  </StartScreenOuterStyle>
);

StartScreen.propTypes = {
  location: PropTypes.object.isRequired,
  currentBusiness: PropTypes.object.isRequired,
};

const StartScreenWrapper = ({ ...props }) => (
  <BusinessContext.Consumer>
    {value => <StartScreen currentBusiness={value} {...props} />}
  </BusinessContext.Consumer>
);

export default StartScreenWrapper;
