import React from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import StripeConnect from '../images/blue-on-light@2x.png';
import { ADD_BANKING_INFO } from '../apollo/mutations';
import { Loading } from './miscellaneous/Loading';
import history from '../history';

const clientId = process.env.REACT_APP_STRIPE_CLIENTID;
const url = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write`;

const StripeButton = ({ loading }) => (
  loading
    ? <Loading />
    : (
      <a href={url} rel="noopener noreferrer">
        <img src={StripeConnect} alt="" style={{ width: '350px' }} />
      </a>
    )
);
export const RegisterStripe = ({ queryString }) => {
  const [addInfo, { data, loading, error }] = useMutation(ADD_BANKING_INFO);
  if (queryString && !data && !loading) addInfo({ variables: { queryString } });
  const attemptTrue = data && data.attachStripeAccount;
  const attemptFalse = data && data.attachStripeAccount === false;
  if (attemptTrue) {
    setTimeout(() => {
      history.replace('/');
      window.location.reload();
    }, 2000);
  }
  return (
    <div className="register-stripe">
      <h4>Connect A Stripe Account To Continue</h4>
      <div className="container-fluid">
        <div className="row justify-content-center">
          {!attemptTrue ? <StripeButton loading={loading} /> : 'Thank You'}
        </div>
        <div className="row justify-content-center">
          {attemptFalse && <span className="error">Please Try Again</span>}
          {error && <span className="error">There was an error!</span>}
        </div>
      </div>
    </div>
  );
};

const RegisterStripeModal = ({ queryString }) => {
  const [addInfo, { data, loading, error }] = useMutation(ADD_BANKING_INFO);
  if (queryString && !data && !loading) addInfo({ variables: { queryString } });
  const attemptTrue = data && data.attachStripeAccount;
  const attemptFalse = data && data.attachStripeAccount === false;
  return (
    <Modal className="input-item-modal stripe-modal" isOpen>
      <ModalHeader>Connect A Stripe Account To Continue</ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row justify-content-center">
            {attemptFalse && <span className="error">Please Try Again</span>}
            {error && <span className="error">There was an error!</span>}
          </div>
          <div className="row justify-content-center">
            {!attemptTrue ? <StripeButton loading={loading} /> : 'Thank You'}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

StripeButton.propTypes = {
  loading: PropTypes.bool.isRequired,
};

RegisterStripe.propTypes = {
  queryString: PropTypes.string.isRequired,
};

RegisterStripeModal.propTypes = {
  queryString: PropTypes.string.isRequired,
};

export default RegisterStripeModal;
