import { gql } from '@apollo/client';

export default gql`
  query findCustomerOrder($phoneNumber: String!) {
    findCustomerOrder(phoneNumber: $phoneNumber) {
      id
      isPaid
    }
  }
`;
