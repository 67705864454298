import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Modal, ModalBody } from 'reactstrap';
import { GET_LOCKER_PIN } from '../../../apollo';
import CustomerThumb from '../../miscellaneous/CustomerThumb';
import OrderThumb from '../../miscellaneous/OrderThumb';

export const ReadOnlyBlock = ({
  itemData: {
    customerInfo: { name, phone },
    orderTotal,
    isPaid,
  },
  lockerPin,
}) => (
  <div className="container-fluid">
    <CustomerThumb name={name} phone={phone} />
    <br />
    <OrderThumb orderTotal={orderTotal} isPaid={isPaid} lockerPin={lockerPin} />
  </div>
);

const ReadOnlyModal = ({
  lockerNumber,
  itemData,
  showModal,
  toggle,
  handleAnyClick,
}) => {
  const { data: { getLockerPin } = {} } = useQuery(GET_LOCKER_PIN, { variables: { lockerNumber }, skip: !lockerNumber });

  return (
    <Modal onClick={handleAnyClick} className="input-item-modal" isOpen={showModal} toggle={toggle}>
      <ModalBody>
        <ReadOnlyBlock itemData={itemData} lockerPin={getLockerPin} />
      </ModalBody>
    </Modal>
  );
};

ReadOnlyBlock.propTypes = {
  itemData: PropTypes.object.isRequired,
  lockerPin: PropTypes.any,
};

ReadOnlyModal.propTypes = {
  lockerNumber: PropTypes.number.isRequired,
  itemData: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleAnyClick: PropTypes.func.isRequired,
};

ReadOnlyBlock.defaultProps = {
  lockerPin: null,
};

export default ReadOnlyModal;
