import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMutation } from '@apollo/client';
import { WeakLink } from '../styledComponents';
import { SubmitButton } from '../miscellaneous/Buttons';
import ModalContent from '../miscellaneous/ModalContent';
import GQLError from '../miscellaneous/GQLError';
import { CREATE_CUSTOMER } from '../../apollo';

const Signup = ({
  linkTo,
  formValues: {
    name = '',
    phone = '',
    confirm = false,
    captcha = '',
    optedIn = true,
  },
  handleChange,
  handleReCaptcha,
  skipAccessCode,
}) => {
  const variables = { CreateCustomer: { name, phone, optedIn }, captcha, skipAccessCode };
  const [submit, { data, loading, error }] = useMutation(CREATE_CUSTOMER, { variables });
  const canSubmit = name && phone.length === 14 && captcha && confirm;

  if (data && data.createCustomer) linkTo('laststep');
  const createCustomer = (e) => { e.preventDefault(); submit(); };
  return (
    <ModalContent header="Join The Yummy Box for a faster pickup experience!">
      <div className="container-fluid">
        <Form onSubmit={createCustomer}>
          <FormGroup>
            <Label for="signup-name-field" className="large-label">Name</Label>
            <Input
              id="signup-name-field"
              name="name"
              onChange={handleChange}
              value={name}
            />
          </FormGroup>
          <FormGroup>
            <Label for="signup-phone-field" className="large-label">Phone Number</Label>
            <Input
              id="signup-phone-field"
              name="phone"
              onChange={handleChange}
              value={phone}
            />
          </FormGroup>
          <FormGroup check>
            <Label for="signup-confirmation-checkbox">
              <Input
                id="signup-confirmation-checkbox"
                name="confirm"
                type="checkbox"
                onChange={handleChange}
                checked={confirm}
              />
              <span>
                I understand that by signing up or transacting with Clever Box Company, I am agreeing to the&nbsp;
                <WeakLink onClick={() => linkTo('tos')}>Terms of Service</WeakLink>
                ,&nbsp;
                <WeakLink onClick={() => linkTo('privacy')}>Privacy Agreement</WeakLink>
                &nbsp;and will receive notification text messages.
              </span>
            </Label>
          </FormGroup>
          <FormGroup>
            {process.env.REACT_APP_APP_ENV !== 'local' && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                onChange={handleReCaptcha}
              />
            )}
          </FormGroup>
          <SubmitButton loading={loading} disabled={!canSubmit} text="Next" />
          <GQLError error={error} />
        </Form>
      </div>
    </ModalContent>
  );
};

Signup.propTypes = {
  linkTo: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReCaptcha: PropTypes.func.isRequired,
  skipAccessCode: PropTypes.bool.isRequired,
};

export default Signup;
