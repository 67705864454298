import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { ImageButton } from '../miscellaneous/Buttons';
import UberEatsLogo from '../../images/icons/ubereats-logo-small.png';
import DoorDashLogo from '../../images/icons/doordash-logo-small.png';
import GrubHubLogo from '../../images/icons/grubhub-logo-small.png';

const DeliveryPickupSectionStyle = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  background-color: white;
  padding-top: 20px;

  & h1 {
    color: black;
    font-family: "Open Sans",sans-serif;
    margin: 0;
  }

  & span {
    color: white;
    background-color: red;
    border-radius: 5px;
    padding: 10px;
  }

  & .pickup-service-buttons {
    margin: auto;
  }
`;

const DeliveryPickupButton = ({ setState, loading }) => {
  const handleButton = value => setState({ modal: 'PickupModal', pickupService: value });

  return (
    <DeliveryPickupSectionStyle>
      <h1>Delivery Driver</h1>
      <div className="pickup-service-buttons">
        <ImageButton img={UberEatsLogo} onClick={() => handleButton('UBEREATS')} disabled={loading} />
        <ImageButton img={DoorDashLogo} onClick={() => handleButton('DOORDASH')} disabled={loading} />
        <ImageButton img={GrubHubLogo} onClick={() => handleButton('GRUBHUB')} disabled={loading} />
      </div>
    </DeliveryPickupSectionStyle>
  );
};

DeliveryPickupButton.propTypes = {
  setState: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DeliveryPickupButton;
