import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { useMutation } from '@apollo/client';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Howl } from 'howler';
import { ASK_FOR_ASSISTANCE } from '../../../apollo';
import { LoadingModal } from '../../miscellaneous/Loading';
import { BusinessContext } from '../../contexts';
import bell from '../../../mixkit-achievement-bell-600.wav';

const sound = new Howl({
  src: [bell],
});

const ModalBodyText = Styled.span`
  color: black;
  font-weight: 600;
`;

const AssistanceModal = ({
  handleAnyClick,
  toggle,
  showModal,
  handleSubmit,
  error,
  data,
}) => {
  if (data) {
    toggle();
    return null;
  }

  return (
    <Modal
      onClick={handleAnyClick}
      className="input-item-modal"
      isOpen={showModal}
      style={{ bottomBorderColor: 'black' }}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Assistance Needed!</ModalHeader>
      <ModalBody style={{ paddingTop: 0 }}>
        <ModalBodyText>
          A customer has requested help!
          <br />
          Please head to the Yummy Box kiosk.
        </ModalBodyText>
      </ModalBody>
      <ModalFooter className="buttons-footer">
        <button
          className="btn btn-danger"
          type="button"
          value
          onClick={handleSubmit}
        >
          Okay
        </button>
        {error && <span>{error}</span>}
      </ModalFooter>
    </Modal>
  );
};

const AssistanceModalMutation = ({ businessId, ...props }) => {
  const [submit, { loading, error, data }] = useMutation(ASK_FOR_ASSISTANCE, { variables: { businessId, setValue: false } });

  useEffect(() => {
    sound.play();
    const interval = setInterval(() => sound.play(), 6000);
    return () => clearInterval(interval);
  }, []);

  if (loading) return <LoadingModal />;

  return (
    <AssistanceModal
      handleSubmit={submit}
      error={error}
      data={data}
      {...props}
    />
  );
};

const AssistanceModalMutationWrapper = props => (
  <BusinessContext.Consumer>
    {({ id }) => <AssistanceModalMutation {...props} businessId={id} />}
  </BusinessContext.Consumer>
);

AssistanceModalMutation.propTypes = {
  businessId: PropTypes.string.isRequired,
};

AssistanceModal.propTypes = {
  data: PropTypes.object,
  handleAnyClick: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  error: PropTypes.any,
};

AssistanceModal.defaultProps = {
  data: null,
  error: null,
};

export default AssistanceModalMutationWrapper;
