import React from 'react';
import Styled from 'styled-components';

const LegendRowWrapper = Styled.div`
  padding: 15px 0;
  display: flex;
  max-width: 600px;
  justify-content: center;
  margin: 15px auto;

  .legend {
    margin: 0 10px;
    font-size: 0.9em;
    color: #9b9b9b;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .legend .dot {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 50%;
    display: inline-block;
  }
  .legend span:last-child {
    margin-bottom: 0px;
    font-size: 0.8rem;
  }

  @media screen and (max-width: 430px) {
    .boogie {
      font-size: 1.4rem;
    }
    .locker-operation-button {
      width: 110px;
    }
    .legend {
      font-size: .9em;
      padding: 0;
    }
  }
  
  @media (max-width: 768px) {
    & {
      padding: 0;
    }
    & .legend {
      margin: 0;
    }
  }
`;
const legend = [{
  meaning: 'Available',
  background: '#60D470',
}, {
  meaning: 'Pending',
  background: '#c7d9de',
}, {
  meaning: 'Confirmed',
  background: '#1E90FF',
}, {
  meaning: 'Freshness alert',
  background: '#FAB12D',
}];

const LockerLegend = () => (
  <div className="container-fluid">
    <LegendRowWrapper>
      {legend.map(({ background, meaning }) => (
        <div key={meaning} className="legend">
          <span className="dot" style={{ background }} />
          <span>{meaning}</span>
        </div>
      ))}
    </LegendRowWrapper>
  </div>
);

export default LockerLegend;
