import { gql } from '@apollo/client';

export default gql`
  query {
    orderPickupForm @client {
      formStep
      newCustomerName
      pickupService
      pickupInputFieldsJSON
      pickupInformation {
        id
        isPaid
        lockerNumbers
        lockerPin
        isNewCustomer
        customerId
      }
      accessCodeIsValid
    }
  }
`;
