import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import Select from 'react-select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import {
  GET_LOCKERS,
  PICKUP_OVERFLOW_ITEM,
  CANCEL_ITEM,
  GET_LOCKER_PIN,
} from '../../../apollo';
import { ReadOnlyBlock } from './ReadOnlyModal';

const options = [
  { value: 'No Verification Code', label: '1. Customer\'s without Verification Code' },
  { value: 'Whoops', label: '2. Whoops, I made a mistake.' },
  { value: 'Freshness Alert', label: '3. Freshness Alert - Left inside The Yummy Box for an extend period of time.' },
  { value: 'Fast Customer', label: '4. Fast Customer - customer arrived before the order makes it into The Yummy Box.' },
  { value: 'No Show', label: '5. No Show - order was paid for but customer neither picked up or canceled.' },
  { value: 'Tablet Issues', label: '6. Tablet Issues - Loss of power or malfunctioning' },
  { value: 'Offline', label: '7. Offline/Lost Wi-Fi Connection' },
];

const findOption = value => options.find(option => option.value === value);

const RemoveAndConfirmModal = ({
  itemData: { id: itemId, ...itemData },
  showModal,
  toggle,
  handleAnyClick,
  pickupOverflowItem,
  lockerNumber,
}) => {
  const [chosenOption, choseOption] = useState({});
  const { data: { getLockerPin } = {} } = useQuery(GET_LOCKER_PIN, { variables: { lockerNumber }, skip: !lockerNumber });
  const mutation = pickupOverflowItem ? PICKUP_OVERFLOW_ITEM : CANCEL_ITEM;
  const option = findOption(chosenOption.value) || options[0];
  const [cancel, { data, error }] = useMutation(mutation, {
    refetchQueries: [{ query: GET_LOCKERS }],
    variables: { itemId, report: pickupOverflowItem ? 'Picked up' : option.value },
  });
  const title = pickupOverflowItem ? 'Has order been picked up?' : 'You want to remove this?';
  if (data && (data.cancelItem || data.pickupOverflowItem)) setTimeout(toggle, 200);

  return (
    <Modal onClick={handleAnyClick} className="input-item-modal" isOpen={showModal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <ReadOnlyBlock itemData={itemData} lockerPin={getLockerPin} />
        {!pickupOverflowItem && (
          <div className="container-fluid" style={{ paddingTop: '40px' }}>
            <h5 className="modal-title">Reason: </h5>
            <Select
              name="removalReason"
              options={options}
              onChange={choseOption}
              value={option}
              isSearchable={false}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className="buttons-footer">
        <button
          className="btn btn-default"
          type="button"
          value={false}
          onClick={toggle}
        >
          Nope!
        </button>
        <button
          className="btn btn-danger"
          type="button"
          value
          onClick={cancel}
        >
          Yes
        </button>
        {error && <span>{error}</span>}
      </ModalFooter>
    </Modal>
  );
};

RemoveAndConfirmModal.propTypes = {
  itemData: PropTypes.object,
  showModal: PropTypes.bool.isRequired,
  pickupOverflowItem: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleAnyClick: PropTypes.func.isRequired,
  lockerNumber: PropTypes.number,
};

RemoveAndConfirmModal.defaultProps = {
  itemData: {},
  lockerNumber: null,
};

export default RemoveAndConfirmModal;
