import { makeVar } from '@apollo/client';

const initialDropOffFormValues = {
  customerId: null,
  orderTotalIsValid: null,
  phoneNumberIsValid: null,
  formStep: 'SELECTTYPE',
  searchCustomer: false,
  lockers: [],
  pickupService: null,
  pickupInputFieldsJSON: '{"isPaid": false, "orderTotal": "0.00"}',
  submittedFormData: null,
};

const initialPickupFormValues = {
  formStep: 'SELECTTYPE',
  newCustomerName: '',
  pickupService: null,
  pickupInputFieldsJSON: '{}',
  pickupInformation: null,
  accessCodeIsValid: null,
};

export const orderDropOffFormVar = makeVar(initialDropOffFormValues);
export const updateOrderDropOffForm = ({ input = {}, fieldInput, closeForm }) => {
  const orderDropOffForm = orderDropOffFormVar();
  const { pickupInputFieldsJSON } = orderDropOffForm;
  const currentInputs = JSON.parse(pickupInputFieldsJSON); // ! READONLY
  const mod = {};

  if (fieldInput) {
    const { name, value } = fieldInput;
    currentInputs[name] = typeof value === 'string' ? value.toUpperCase() : value;
    mod.pickupInputFieldsJSON = JSON.stringify(currentInputs);
  }

  if (closeForm) {
    orderDropOffFormVar(initialDropOffFormValues);
    return;
  }

  orderDropOffFormVar({
    ...orderDropOffForm,
    ...input,
    ...mod,
  });
};

export const orderPickupFormVar = makeVar(initialPickupFormValues);
export const updateOrderPickupForm = ({ input = {}, fieldInput, closeForm }) => {
  const orderPickupForm = orderPickupFormVar();
  const { pickupInputFieldsJSON } = orderPickupForm;
  const { pickupInformation } = input;
  const mod = {};

  if (fieldInput) {
    const { name, value } = fieldInput;
    const currentInputs = JSON.parse(pickupInputFieldsJSON);
    currentInputs[name] = typeof value === 'string' ? value.toUpperCase() : value;
    mod.pickupInputFieldsJSON = JSON.stringify(currentInputs);
  }

  if (pickupInformation) {
    if (pickupInformation.isNewCustomer) mod.formStep = 'NEWCUSTOMER';
    else mod.formStep = 'PICKUPINFORMATION';
  }

  if (closeForm) {
    orderPickupFormVar(initialPickupFormValues);
    return;
  }

  orderPickupFormVar({
    ...orderPickupForm,
    ...input,
    ...mod,
  });
};
