import React from 'react';
import PropTypes from 'prop-types';
import UberEatsPickupFields from './UberEatsPickupFields';
import DoorDashPickupFields from './DoorDashPickupFields';

const DynamicServiceInput = ({
  reactiveVar,
  pickupService,
  fieldValues,
  handleSubmit,
}) => {
  const handleInput = ({ target: { name, value } }) => reactiveVar({ fieldInput: { name, value } });
  const fieldProps = { handleInput, fieldValues, handleSubmit };
  return (
    <div className="container-fluid delivery-service-input-form">
      <div className="row justify-content-center">
        {pickupService === 'UBEREATS' && <UberEatsPickupFields {...fieldProps} />}
        {/DOORDASH|GRUBHUB/.test(pickupService) && <DoorDashPickupFields pickupService={pickupService} {...fieldProps} />}
      </div>
    </div>
  );
};

DynamicServiceInput.propTypes = {
  reactiveVar: PropTypes.func.isRequired,
  pickupService: PropTypes.string,
  fieldValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

DynamicServiceInput.defaultProps = {
  pickupService: null,
};

export default DynamicServiceInput;
