import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Locker from '../Locker';
import { updateOrderDropOffForm, ORDER_DROP_OFF_FORM } from '../../../apollo';

const LockerColumn = ({ lockers, onClick, ...props }) => {
  const { data: { orderDropOffForm } } = useQuery(ORDER_DROP_OFF_FORM);

  return lockers.map((locker) => {
    const { lockerNumber } = locker;
    const selectedLockers = orderDropOffForm.lockers;
    const selected = Boolean(selectedLockers.find(v => v === lockerNumber));
    let newLockerSelections = [].concat(selectedLockers);
    if (selected) {
      newLockerSelections = newLockerSelections.filter(x => x !== lockerNumber);
    } else {
      newLockerSelections.push(lockerNumber);
    }
    const selectLocker = () => updateOrderDropOffForm({ input: { lockers: newLockerSelections } });
    const handleClick = locker.item ? onClick : selectLocker;

    return (
      <Locker
        lockerData={locker}
        onClick={locker.item ? onClick : handleClick}
        selected={selected}
        key={Math.random()}
        {...props}
      />
    );
  });
};

const LockerGridView = ({ data, ...props }) => {
  // If you modify the lockers array, it affects the cache.
  const { lockerConfig } = data;
  const lockers = [...data.lockers];

  return lockerConfig.map(v => (
    <div className="locker-column" key={Math.random()}>
      <LockerColumn lockers={lockers.splice(0, v)} {...props} />
    </div>
  ));
};

LockerColumn.propTypes = {
  lockers: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

LockerGridView.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LockerGridView;
