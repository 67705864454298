import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { GET_LOCKER_PIN } from '../../../apollo';

const GetLockerPinModal = ({
  lockerData: { lockerNumber },
  handleAnyClick,
  toggle,
  showModal,
}) => {
  const [query, {
    loading,
    error,
    data: { getLockerPin } = {},
  }] = useLazyQuery(GET_LOCKER_PIN, { variables: { lockerNumber } });

  useEffect(() => {
    if (lockerNumber) query();
  }, [lockerNumber]);

  if (!lockerNumber) return false;

  return (
    <Modal
      onClick={handleAnyClick}
      className="input-item-modal"
      isOpen={showModal}
      style={{ bottomBorderColor: 'black' }}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Shhh. Don&#39;t show no one!</ModalHeader>
      <ModalBody>
        {loading && <div />}
        {error && 'Error!: Cannot retrieve pin'}
        {(!loading && !error) && <div className="container-fluid">{getLockerPin}</div>}
      </ModalBody>
    </Modal>
  );
};

GetLockerPinModal.propTypes = {
  lockerData: PropTypes.object.isRequired,
  handleAnyClick: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default GetLockerPinModal;
