import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Router,
} from 'react-router-dom';
import Styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import history from '../history';
import AuthRoute from './Routing/AuthRoute';
import FGHeader from './FGHeader';
import Lockers from './runner';
import PaymentPage from './PaymentPage';
import StartScreen from './StartScreen';
import MainScreen from './MainScreen';
import SuccessfulPayment from './PaymentPage/SuccessfulPayment';
import SignUpSkipAccessCode from './signUpSkipAccessCode';
import { Loading } from './miscellaneous/Loading';
import NoMatch from './404';

const FlexFullHeight = Styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ returnTo: window.location.origin });
  }, []);

  return <Loading />;
};

const Routes = () => (
  <Router basename="/" history={history}>
    <FlexFullHeight>
      <FlexFullHeight>
        <FGHeader />
        <Switch>
          <AuthRoute exact path="/" component={StartScreen} />
          <AuthRoute exact path="/home" component={MainScreen} />
          <AuthRoute exact path="/locker" component={Lockers} />
          <Route exact path="/payment/thank-you" component={SuccessfulPayment} />
          <Route exact path="/payment/:orderId" component={PaymentPage} />
          <Route exact path="/pc-sign-up" component={SignUpSkipAccessCode} />
          <Route exact path="/logout" component={Logout} />
          <Route component={NoMatch} />
        </Switch>
      </FlexFullHeight>
    </FlexFullHeight>
  </Router>
);

export default Routes;
