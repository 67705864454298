import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { Button } from 'reactstrap';

const ImageButtonStyled = Styled(Button)`
  background: none;
  width: 165px;
  overflow: hidden;
  margin: 0px 15px;
  border-width: 2px;
  border-color: #6c757d;
  font-weight: bold;

  .pickup-service-buttons & {
    height: 170px;
  }
  .pickup-type-buttons & {
    height: 200px;
  }

  @media (hover: hover) {
    &:hover {
      border-color: #6c757d;
      background-color: white;
      box-shadow: 5px 6px 10px 0px #6c757d;
    }
  }

  & img {
    width: 100%;
    display: block;
    padding: 0px 5px;
  }

  & span {
    display: block;
    color: #343434;
    padding: 3px 0;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: none;
  }
`;

const ImageButton = ({
  img,
  text,
  onClick,
  children,
  disabled,
}) => (
  <ImageButtonStyled
    type="button"
    onClick={onClick}
    outline
    disabled={disabled}
  >
    <img src={img} alt="" />
    {(text || children) && <span>{children || text}</span>}
  </ImageButtonStyled>
);

ImageButton.propTypes = {
  img: PropTypes.string.isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

ImageButton.defaultProps = {
  text: '',
  children: null,
  disabled: false,
};

export default ImageButton;
