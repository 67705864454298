import React from 'react';
import Styled from 'styled-components';
import ModalContent from '../miscellaneous/ModalContent';
import HowItWorksImage from '../../images/HowItWorks-TYB.jpg';

const ModalContentWrapper = Styled(ModalContent)`
  & .how-it-works-image {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 90px;
  }

  & .how-it-works-done-button {
    margin-bottom: 120px;
  }
`;

const LastStep = () => (
  <ModalContentWrapper header="Thank you for signing up for convenient pickup!">
    <img src={HowItWorksImage} alt="" className="how-it-works-image" />
    <div className="how-it-works-done-button">
      <a href="https://www.cleverboxcompany.com/" className="btn btn-primary w-100">DONE</a>
    </div>
  </ModalContentWrapper>
);

LastStep.propTypes = {
};

export default LastStep;
