import React from 'react';
import PropTypes from 'prop-types';

const LoadingText = ({ active }) => (
  active
    ? <div className="loading-cmp"><span className="loading-text">Loading...</span></div>
    : null
);

LoadingText.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default LoadingText;
