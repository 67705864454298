import React from 'react';
import PropTypes from 'prop-types';

const getClass = bool => (bool ? 'nav-item active' : 'nav-item');

const error = value => (value === false ? <span className="error-mark">!</span> : null);

const StepsNav = ({ formData }) => {
  const { formStep, phoneNumberIsValid, orderTotalIsValid } = formData;
  return (
    <div className="steps-nav floated">
      <div className={getClass(formStep === 'CUSTOMERPICKUP')}>
        <span className="ion-person" />
        {error(phoneNumberIsValid)}
      </div>
      <div className={getClass(formStep === 'CUSTOMERPICKUP|1')}>
        <span className="ion-bag" />
        {error(orderTotalIsValid)}
      </div>
      <div className={getClass(formStep === 'PICKUPINFORMATION')}>
        <span className="ion-checkmark-round" />
      </div>
    </div>
  );
};

StepsNav.propTypes = {
  formData: PropTypes.object.isRequired,
};

export default StepsNav;
