import { gql } from '@apollo/client';

export default gql`
  query {
    lockers: YummyBusiness {
      id
      lockerConfig
      lockers {
        lockerNumber
        orderType
        item {
          id
          customerInfo {
            id
            name
            phone
          }
          ... on Order {
            orderTotal
            isPaid
          }
          customerIsAware
          createdAt
        }
        status
        isDisabled
        disabledReason
      }
      overflowItems {
        id
        orderType
        customerInfo {
          name
          phone
        }
        ... on Order {
            orderTotal
            isPaid
          }
        customerIsAware
        isOverflowItem
        createdAt
      }
      overflowItems {
        id
        orderType
        customerInfo {
          name
          phone
        }
        ... on Order {
            orderTotal
            isPaid
          }
        customerIsAware
        isOverflowItem
        createdAt
      }
    }
  }
`;
