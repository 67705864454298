import React from 'react';
import PropTypes from 'prop-types';
import ImageButton from './Buttons/ImageButton';
import UberEatsLogo from '../../images/icons/ubereats-logo-small.png';
import DoorDashLogo from '../../images/icons/doordash-logo-small.png';
import GrubHubLogo from '../../images/icons/grubhub-logo-small.png';

const SelectPickupService = ({ reactiveVar }) => {
  const handleButton = value => reactiveVar({ input: { pickupService: value } });
  return (
    <div className="container-fluid">
      <div className="row justify-content-center pickup-service-buttons">
        <ImageButton img={UberEatsLogo} onClick={() => handleButton('UBEREATS')} />
        <ImageButton img={DoorDashLogo} onClick={() => handleButton('DOORDASH')} />
        <ImageButton img={GrubHubLogo} onClick={() => handleButton('GRUBHUB')} />
      </div>
    </div>
  );
};

SelectPickupService.propTypes = {
  reactiveVar: PropTypes.func.isRequired,
};

export default SelectPickupService;
