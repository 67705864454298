import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { UPDATE_CUSTOMER } from '../../apollo';
import { Loading } from '../miscellaneous/Loading';
import { SubmitButton } from '../miscellaneous/Buttons';

const NewCustomerStep = ({ customerId, newCustomerName, setState }) => {
  const [set, { data: { updateCustomer } = {}, loading }] = useMutation(UPDATE_CUSTOMER);

  useEffect(() => {
    if (updateCustomer) setState({ formStep: 'PICKUPINFORMATION' });
  }, [updateCustomer]);

  if (loading) return <Loading />;

  const handleSubmit = (e) => {
    e.preventDefault();
    return newCustomerName && set({ variables: { id: customerId, input: { name: newCustomerName } } });
  };
  const handleKeyboard = ({ target: { value } }) => setState({ newCustomerName: value });
  const handleSkip = () => set({ variables: { id: customerId, input: {} } });

  return (
    <div className="order-modal-phone-input">
      <div className="container-fluid customer-input-name">
        <br />
        <Form onSubmit={handleSubmit}>
          <div className="row justify-content-center">
            <FormGroup style={{ width: '100%' }}>
              <Label for="customerNameField" className="large-label">What&#39;s your name?</Label>
              <Input
                id="customerNameField"
                type="text"
                name="customerName"
                className="large-input"
                style={{ margin: '15px', width: '100%', maxWidth: '408px' }}
                value={newCustomerName}
                onChange={handleKeyboard}
                placeholder="Henry Ford"
                autoFocus
                autoComplete="off"
              />
            </FormGroup>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-6">
              <SubmitButton text="Skip" color="danger" onClick={handleSkip} />
            </div>
            <div className="col-6">
              <SubmitButton onClick={handleSubmit} />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

NewCustomerStep.propTypes = {
  setState: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  newCustomerName: PropTypes.string,
};

NewCustomerStep.defaultProps = {
  newCustomerName: '',
};

export default NewCustomerStep;
