import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverBody } from 'reactstrap';
import QuestionMarkIcon from '../../../images/icons/questionmark_icon.png';

const Tooltip = ({ tooltipOpen, toggle }) => (
  <Popover placement="right" isOpen={tooltipOpen} target="Popover1" toggle={toggle}>
    <PopoverBody>List view will only show you occupied lockers.</PopoverBody>
  </Popover>
);

const TooltipButton = () => {
  const [tooltipOpen, setTooltipState] = useState(false);
  const toggle = () => setTooltipState(!tooltipOpen);

  return (
    <>
      <button id="Popover1" onClick={toggle} type="button">
        <img src={QuestionMarkIcon} alt="" style={{ width: '40px' }} />
      </button>
      <Tooltip tooltipOpen={tooltipOpen} toggle={toggle} />
    </>
  );
};

Tooltip.propTypes = {
  tooltipOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default TooltipButton;
