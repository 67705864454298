import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { injectStripe } from 'react-stripe-elements';
import CardSection from './CardSection';
import { OrderContext } from '../contexts';

const StripeError = Styled.span`
  font-size: 15px;
  color: red;
  text-align: center;
  display: block;
  margin-bottom: 5px;
`;

const StripeButton = Styled.button`
  width: 100%;
  height: 60px;
  font-weight: 600;
  letter-spacing: 1px;
`;

const CheckoutForm = ({ stripe, submitToken, error }) => {
  // const [error, setError] = useState(null);
  // TODO: Maybe some type of error ui?
  const { orderId, customerName: name } = useContext(OrderContext);
  const handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    stripe.createToken({ name }).then(({ token }) => {
      if (!token) return false;
      // if (!token) return setError(true);
      const tokenString = JSON.stringify(token);
      return submitToken({ variables: { orderId, token: tokenString } });
    });

    // However, this line of code will do the same thing:
    //
    // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});

    // You can also use createSource to create Sources. See our Sources
    // documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
    //
    // this.props.stripe.createSource({type: 'card', owner: {
    //   name: 'Jenny Rosen'
    // }});
  };
  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <span style={{ width: '100%' }}>
        <CardSection />
      </span>
      <span style={{ width: '100%' }}>
        {error && <StripeError>There was an error processing your payment. Please try again</StripeError>}
        <StripeButton className="btn btn-primary" type="submit">Submit!</StripeButton>
      </span>
    </form>
  );
};

CheckoutForm.propTypes = {
  stripe: PropTypes.object.isRequired,
  submitToken: PropTypes.func.isRequired,
  error: PropTypes.object,
};

CheckoutForm.defaultProps = {
  error: null,
};

export default injectStripe(CheckoutForm);
