import React from 'react';
import PropTypes from 'prop-types';
import KeypadKey from './KeypadKey';

const KeypadRow = ({ row, lastRowFunc, onClick }) => (
  row.map((value, i) => (
    <KeypadKey
      key={value}
      value={value}
      onClick={lastRowFunc ? lastRowFunc[i] : onClick}
    />
  ))
);

KeypadRow.propTypes = {
  row: PropTypes.array,
  lastRowFunc: PropTypes.array,
  onClick: PropTypes.func,
};

KeypadRow.defaultProps = {
  row: [],
  lastRowFunc: null,
  onClick: null,
};

export default KeypadRow;
