import { gql } from '@apollo/client';

export default gql`
  query Order($orderId: ID!){
    getOrder: Order(id: $orderId, unpaid: true) {
      id
      orderTotal
      isPaid
      customerInfo {
        id
        name
      }
    }
  }
`;
