import React, { useState, useEffect } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import RunnerLoginModal from './runner/RunnerLoginModal';
import '../styles/navbar.css';
import TYB_LOGO from '../images/logo/TYB_logo_header2.svg';

const FGHeader = () => {
  const [isOpenModal, openModal] = useState(false);
  const { pathname } = useLocation();
  const navbarProp = pathname === '/locker'
    ? { href: '/home' }
    : { href: '#', onClick: () => openModal(true) };

  useEffect(() => { openModal(false); }, [pathname]);

  if (pathname === '/') return <div />;

  return (
    <Navbar className="FG-navbar" dark expand="md">
      <NavbarBrand className="brand-image" {...navbarProp}>
        <img src={TYB_LOGO} alt="The Yummy Box" />
      </NavbarBrand>
      {isOpenModal && <RunnerLoginModal toggleModal={() => openModal(!isOpenModal)} />}
    </Navbar>
  );
};

export default FGHeader;
