import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { StripeProvider } from 'react-stripe-elements';
import MyStoreCheckout from './MyStoreCheckout';

const PaymentPage = Styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  margin: 30px auto;

  @media all and (max-width: 660px) {
    & {
      padding: 15px;
      border-width: 0px;
      width: 95%;
      margin: 15px auto;
    }
  }
`;

const Greeting = Styled.span`
  width: 100%;
  text-align: left;
  display: block;
  color: #333;
`;

const OrderTotalWrapper = Styled.span`
  display: block;
  width: 100%;
  color: #333;
  margin-top: 30px;
  border-top: 1px solid #e2e2e2;
  padding-top: 30px;

  @media all and (max-width: 660px) {
    margin-top: 10px;
    padding-top: 10px;
  }
`;

const OrderTotal = Styled.span`
  font-weight: 800;
  font-size: 1.2rem;
`;

const PaymentSection = ({ order: { customerInfo: { name } = {}, orderTotal } = {} }) => {
  const orderTotalFormatted = `$${parseFloat(orderTotal).toFixed(2)}`;
  const greeting = name === 'N/A' ? 'Hey!' : `Hey ${name}!`;
  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
      <PaymentPage className="container">
        <Greeting>{greeting}</Greeting>
        <OrderTotalWrapper>
          Your order total is&nbsp;
          <OrderTotal>{orderTotalFormatted}</OrderTotal>
          .
        </OrderTotalWrapper>
        <MyStoreCheckout />
      </PaymentPage>
    </StripeProvider>
  );
};

PaymentSection.propTypes = {
  order: PropTypes.object.isRequired,
};

export default PaymentSection;
