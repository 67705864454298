import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_LOCKERS } from '../../../apollo';
import LockerListView from './LockerListView';
import OverflowItemList from './OverflowItemList';
import LockerGridView from './LockerGridView';
import CreateReservationButton from '../CreateReservationButton';
import Toolbar from './Toolbar';

const LockersView = ({
  isGridView,
  handleLockerClick,
  handleLongLockerClick,
  toggleView,
  itemMethods,
}) => {
  const {
    loading,
    error,
    data: { lockers } = {},
    refetch,
  } = useQuery(GET_LOCKERS, { pollInterval: 300000, fetchPolicy: 'cache-and-network' });

  if (loading) return <div />;
  if (error) {
    return <div className="justify-content-center">Error loading the page. Refresh or contact admin if this persists!</div>;
  }

  return (
    <>
      <Toolbar
        {...{ refresh: async () => refetch(), toggleView, isGridView }}
        oversizeCount={lockers.overflowItems.length}
      />
      {isGridView && <CreateReservationButton onClick={handleLockerClick} />}
      <div className={`lockers-container ${isGridView ? 'gridview' : 'listview'}`}>
        {isGridView
          ? <LockerGridView data={lockers} onClick={handleLockerClick} onLongClick={handleLongLockerClick} />
          : (
            <>
              <LockerListView
                lockers={lockers.lockers || []}
                onClick={handleLockerClick}
                itemMethods={itemMethods}
              />
              <OverflowItemList
                overflowItems={lockers.overflowItems}
                onClick={handleLockerClick}
                itemMethods={itemMethods}
              />
            </>
          )}
      </div>
    </>
  );
};

LockersView.propTypes = {
  isGridView: PropTypes.bool.isRequired,
  handleLockerClick: PropTypes.func.isRequired,
  handleLongLockerClick: PropTypes.func.isRequired,
  toggleView: PropTypes.func.isRequired,
  itemMethods: PropTypes.object.isRequired,
};

export default LockersView;
