import React from 'react';
import PropTypes from 'prop-types';
import { SubmitButton } from '../miscellaneous/Buttons';
import TermsOfServiceContent from './TermsOfServiceContent';
import ModalContent from '../miscellaneous/ModalContent';

const TermsOfService = ({ handleBack }) => (
  <ModalContent header="Terms Of Service">
    <div className="container-fluid">
      <TermsOfServiceContent />
      <br />
      <SubmitButton color="danger" text="Back" onClick={handleBack} />
    </div>
  </ModalContent>
);

TermsOfService.propTypes = {
  handleBack: PropTypes.func.isRequired,
};

export default TermsOfService;
