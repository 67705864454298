import { gql } from '@apollo/client';

export default gql`
  mutation moveItem($newLockerNumber: Int!, $oldLockerNumber: Int!) {
    moveItem(newLockerNumber: $newLockerNumber, oldLockerNumber: $oldLockerNumber, businessType: YUMMY) {
      lockerNumber
      item {
        id
        customerInfo {
          id
          name
          phone
        }
        ... on Order {
          orderTotal
          isPaid
        }
        createdAt
      }
      status
    }
  }
`;
